<template>
  <div
    class="air-dialog"
    v-loading="isLoading"
    element-loading-text="上传中，请稍等"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.4)"
    align="center"
  >
    <div class="air-dialog-close" align="right">
      <button type="button" @click="close">×</button>
    </div>
    <div class="air-dialog-title">
      <p>企业认证</p>
    </div>
    <div class="air-dialog-content">
      <el-form :model="authForm" ref="authForm">
        <el-form-item>
          <div>
            <el-upload
              :action="uploadCertificate"
              class="avatar-uploader"
              :headers="headers"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <div class="upload">
                <img
                  v-if="imageUrl"
                  :src="imageUrl"
                  class="image-card"
                  alt=""
                />
              </div>
            </el-upload>
          </div>
          <div>
            <p>
              企业执照
            </p>
          </div>
        </el-form-item>
        <el-form-item>
          <div align="left" style="width: 400px;margin-left: -80px">
            <el-row>
              <el-col :span="5">
                <p>企业名称</p>
              </el-col>
              <el-col :span="19">
                <el-input
                  placeholder="请输入企业名称"
                  v-model="authForm.companyName"
                ></el-input>
              </el-col>
            </el-row>
          </div>
        </el-form-item>
        <el-form-item>
          <div align="left" style="width: 400px;margin-left: -80px">
            <el-row>
              <el-col :span="5">
                <p>企业号</p>
              </el-col>
              <el-col :span="19">
                <el-input
                  placeholder="请输入企业编号"
                  v-model="authForm.companyCode"
                ></el-input>
              </el-col>
            </el-row>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="air-dialog-footer">
      <button type="button" @click="save" class="air-btn-save">保存</button>
    </div>
  </div>
</template>

<script>
import { uploadCertificate } from "@/libs/dict/air-cloud";
import { getToken } from "@/libs/util";
export default {
  name: "AuthRequest",
  props: {
    companyNameRules: {
      type: Array,
      default: () => {
        return [
          { required: true, message: "企业名称不能为空", trigger: "blur" }
        ];
      }
    },
    companyCodeRules: {
      type: Array,
      default: () => {
        return [
          { required: true, message: "企业编码不能为空", trigger: "blur" }
        ];
      }
    },
    tradingCertificateRules: {
      type: Array,
      default: () => {
        return [
          { required: true, message: "企业三证不能为空", trigger: "blur" }
        ];
      }
    }
  },
  data() {
    return {
      isLoading: false,
      uploadCertificate,
      headers: {
        token: this.$store.state.user.token
          ? this.$store.state.user.token
          : getToken()
      },
      imageUrl: "",
      authForm: {
        encryptionFileNames: "",
        companyName: "",
        companyCode: "",
        tradingCertificate: "",
        createBy: this.$store.state.user.userInfo.userId
      }
    };
  },
  computed: {
    rules() {
      return {
        companyName: this.companyNameRules,
        companyCode: this.companyCodeRules,
        tradingCertificate: this.tradingCertificateRules
      };
    }
  },
  methods: {
    close() {
      this.$emit("close", {});
    },
    save() {
      let data = {
        companyName: this.authForm.companyName,
        companyCode: this.authForm.companyCode,
        tradingCertificate: this.authForm.tradingCertificate,
        createBy: this.authForm.createBy,
        encryptionFileName: this.authForm.encryptionFileName,
        companyId: this.$store.state.user.userInfo.companyId,
        contacts: this.$store.state.user.userInfo.realName,
        phone: this.$store.state.user.userInfo.mobile
      };
      this.$refs.authForm.validate(valid => {
        if (valid) {
          this.$emit("save", data);
        }
      });
    },
    handleAvatarSuccess(res, file) {
      if (res.code === 200) {
        this.imageUrl = URL.createObjectURL(file.raw);
        this.authForm.encryptionFileName = res.data[0].encryptionFileName;
        this.isLoading = false;
      } else {
        this.$message.warning("上传失败，请重新上传");
        this.isLoading = false;
      }
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      this.isLoading = true;
      const isLt5M = file.size / 1024 / 1024 < 5;
      //
      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG 格式!");
      // }
      if (!isLt5M) {
        this.$message.error("上传证件图片大小不能超过 5MB!");
        this.isLoading = false;
      }
      return isLt5M;
    }
  }
};
</script>

<style scoped></style>
