<template>
  <div>
    <div>
      <div class="live-play" :style="style">
        <video muted autoplay id="video" ></video>
        <div class="live-play-menu">
          <el-row>
            <el-col :span="12">
              <el-button @click="switchVR()" size="mini">{{
                showVR ? "标准" : "VR"
              }}</el-button>
            </el-col>
            <el-col :span="12">
              <el-button @click="fullScreen()" size="mini">
                {{ fullPage ? "窗口" : "全屏" }}
              </el-button>
            </el-col>
          </el-row>
        </div>
        <div class="live-play-expanded" v-show="showVR">
          <div id="container-vr"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { wsPlayer } from "@/libs/sdk/live/wsPlayer";
import { VR } from "@/libs/sdk/live/mxreality";
import * as THREE from "three";

export default {
  name: "VideoPlayer",
  props: {
    playInfo: {
      type: Object,
      default() {
        return {
          url: "",
          deviceType: 0,
          type: "live"
        };
      }
    }
  },
  data() {
    return {
      videoURL: "",
      showVR: false,
      vr: "",
      container: "",
      renderer: "",
      style: {},
      fullPage: false,
      player: null
    };
  },
  watch: {
    playInfo: function(newValue) {
      if (newValue.type === "live") {
        this.formatLiveURL(newValue.url);
      } else {
        this.videoURL = newValue.url;
        console.log(this.videoURL);
        this.playHistory();
      }
    }
  },
  mounted() {
    console.log(this.playInfo);
    if (this.playInfo.type === "live") {
      this.formatLiveURL(this.playInfo.url);
    } else {
      this.videoURL = this.playInfo.url;
      this.playHistory();
    }
  },
  methods: {
    formatLiveURL(url) {
      let index = url.indexOf("/live");
      let str2 = url.substr(index + 1, url.length);
      this.videoURL = "wss://acme-vision.com/livews/" + str2 + ".live.mp4";
      console.log(this.videoURL);
      this.play();
    },
    play() {
      console.log("play");
      let video = document.getElementById("video");
      console.log(video);
      this.player = new wsPlayer("video", this.videoURL);
      this.player.open();
    },
    close() {
      console.log("close");
      this.player.close();
    },
    playHistory() {
      let dom = document.getElementById("video");
      dom.src = this.videoURL;
      dom.play();
    },
    switchVR() {
      if (!this.showVR) {
        this.showVR = true;
        // console.log(THREE);
        window.THREE = THREE; // 重要，不设置则会报THREE未定义错误！！！！！！
        let videoTag = document.getElementById("video");
        if (videoTag.paused) {
          videoTag.play();
        }
        this.container = document.getElementById("container-vr");
        this.renderer = new THREE.WebGLRenderer();
        console.log(this.container);
        this.container.appendChild(this.renderer.domElement);

        let scene = new THREE.Scene();
        let geometry = new THREE.SphereBufferGeometry(200, 60, 40);
        geometry.scale(-1, 1, 1);
        console.log("120");
        console.log(videoTag)
        let texture = new THREE.VideoTexture(videoTag);

        let material = new THREE.MeshBasicMaterial({
          map: texture
        });
        let mesh = new THREE.Mesh(geometry, material);
        scene.add(mesh);
        console.log("128");
        this.vr = new VR(scene, this.renderer, this.container);

        this.vr.init(function() {});
        console.log("132");
      } else {
        console.log(this.renderer.domElement);
        while (this.container.children.length) {
          this.container.removeChild(this.container.children[0]);
        }
        // this.container.removeChild(this.renderer.domElement);
        this.renderer.dispose();
        this.renderer.forceContextLoss();
        // this.renderer.context = null;
        this.renderer.domElement = null;
        this.renderer = null;
        this.showVR = false;
      }
    },
    fullScreen() {
      this.fullPage = !this.fullPage;
      if (this.fullPage) {
        this.$emit("full", this.fullPage);
        this.style = {
          position: "fixed",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: "100%",
          height: "100%"
        };
      } else {
        this.$emit("full", this.fullPage);
        this.style = {};
      }
    }
  }
};
</script>

<style scoped lang="less">
.live {
  width: 100%;
  &-play {
    width: 610px;
    height: 305px;
    position: relative;
    video {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
    &-expanded {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
    }
    &-menu {
      position: absolute;
      z-index: 2;
      width: 120px;
      height: 50px;
      bottom: 0;
      right: 0;
      color: white;
      font-size: 24px;
    }
  }
}
</style>
