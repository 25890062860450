import liveMain from "@/components/live/LiveMain.vue";
import Main from "@/components/websites/Main.vue";
import airMain from "@/components/air-cloud/AirMain.vue";
import EditorMain from "@/components/ar-editor/EditorMain.vue";
export default [
  // 官网相关
  {
    path: "/",
    name: "main",
    redirect: "/",
    component: Main,
    children: [
      {
        path: "/",
        name: "home",
        meta: {
          title: "首页",
          notCache: true
        },
        component: () => import("@/views/websites/home/home.vue")
      },
      {
        path: "/product",
        name: "product",
        meta: {
          title: "产品介绍",
          notCache: true
        },
        component: () => import("@/views/websites/product/product.vue")
      },
      {
        path: "/solution",
        name: "solution",
        meta: {
          title: "解決方案",
          notCache: true
        },
        component: () => import("@/views/websites/solution/solution.vue")
      },

      {
        path: "/solution/desc",
        name: "solutionDesc",
        meta: {
          title: "解決方案",
          notCache: true
        },
        component: () => import("@/views/websites/solution/solution-desc.vue")
      },
      {
        path: "/case",
        name: "case",
        meta: {
          title: "客户案例",
          notCache: true
        },
        component: () => import("@/views/websites/case/case.vue")
      },
      {
        path: "/about",
        name: "about",
        meta: {
          title: "关于我们",
          notCache: true
        },
        component: () => import("@/views/websites/about/about.vue")
      }
    ]
  },

  // 移动端
  {
    path: "/wap/",
    name: "wapHome",
    component: () => import("../views/wap-page/home/Home.vue")
  },
  {
    path: "/wap/product",
    name: "wapProduct",
    component: () => import("../views/wap-page/product/Product.vue")
  },
  {
    path: "/wap/solution",
    name: "wapSolution",
    component: () => import("../views/wap-page/solution/Solution.vue")
  },
  {
    path: "/wap/case",
    name: "wapCase",
    component: () => import("../views/wap-page/case/Case.vue")
  },
  {
    path: "/wap/about",
    name: "wapAbout",
    component: () => import("../views/wap-page/about/About.vue")
  },
  // air-cloud
  // {
  //   path: "/aircloud/login",
  //   name: "airLogin",
  //   meta: {
  //     title: "Login - 登录"
  //   },
  //   component: () => import("@/views/air-cloud/login")
  // },
  // {
  //   path: "/aircloud/register",
  //   name: "airRegister",
  //   meta: {
  //     title: "Register - 注册"
  //   },
  //   component: () => import("@/views/air-cloud/register")
  // },
  // {
  //   path: "/aircloud/home",
  //   name: "airService",
  //   meta: {
  //     title: "Service - 服务列表"
  //   },
  //   component: () => import("@/views/air-cloud/service-project")
  // },
  // {
  //   path: "/aircloud",
  //   name: "_aircloud",
  //   redirect: "/aircloud/home",
  //   component: airMain,
  //   children: [
  //     {
  //       path: "/aircloud/service",
  //       name: "serviceOrder",
  //       meta: {
  //         title: "Service - 服务订单"
  //       },
  //       component: () => import("@/views/air-cloud/admin-service")
  //     },
  //     {
  //       path: "/aircloud/auth",
  //       name: "serviceAuth",
  //       meta: {
  //         title: "Service - 认证审核"
  //       },
  //       component: () => import("@/views/air-cloud/admin-auth")
  //     },
  //     {
  //       path: "/aircloud/user",
  //       name: "airUser",
  //       meta: {
  //         title: "User - 用户管理"
  //       },
  //       component: () => import("@/views/air-cloud/user")
  //     },
  //     {
  //       path: "/aircloud/face",
  //       name: "serviceFace",
  //       meta: {
  //         title: "Service - 人脸识别"
  //       },
  //       component: () => import("@/views/air-cloud/service-face")
  //     },
  //     {
  //       path: "/aircloud/image",
  //       name: "serviceImage",
  //       meta: {
  //         title: "Service - 图像识别"
  //       },
  //       component: () => import("@/views/air-cloud/service-image")
  //     },
  //     {
  //       path: "/aircloud/car",
  //       name: "serviceCar",
  //       meta: {
  //         title: "Service - 车牌识别"
  //       },
  //       component: () => import("@/views/air-cloud/service-car")
  //     },
  //     {
  //       path: "/aircloud/sop",
  //       name: "serviceSop",
  //       meta: {
  //         title: "Service - 电子SOP"
  //       },
  //       component: () => import("@/views/air-cloud/service-sop")
  //     },
  //     {
  //       path: "/aircloud/love",
  //       name: "serviceLove",
  //       meta: {
  //         title: "Service - 相亲页面"
  //       },
  //       component: () => import("@/views/air-cloud/service-love")
  //     }
  //   ]
  // },
  //直播相关
  {
    path: "/live/login",
    name: "liveLogin",
    meta: {
      title: "Login - 登录"
    },
    component: () => import("@/views/vr-live/login")
  },
  {
    path: "/live",
    name: "_liveHome",
    redirect: "/live/home",
    component: liveMain,
    children: [
      {
        path: "/live/home",
        name: "liveHome",
        meta: {
          title: "Home - 主页"
        },
        component: () => import("@/views/vr-live/home")
      },
      {
        path: "/live/device",
        name: "liveDevice",
        meta: {
          title: "Device - 设备管理"
        },
        component: () => import("@/views/vr-live/device")
      },
      {
        path: "/live/history",
        name: "liveHistory",
        meta: {
          title: "History - 视频回看"
        },
        component: () => import("@/views/vr-live/history")
      }
    ]
  },
  // editor
  // {
  //   path: "/esop/editor",
  //   name: "esopEditor",
  //   meta: {
  //     title: "Editor - 编辑"
  //   },
  //   component: () => import("@/views/ar-editor/editor")
  // },
  // {
  //   path: "/esop",
  //   name: "_editorProject",
  //   redirect: "/esop/project",
  //   component: EditorMain,
  //   children: [
  //     {
  //       path: "/esop/project",
  //       name: "editorProject",
  //       meta: {
  //         title: "Home - 主页"
  //       },
  //       component: () => import("@/views/ar-editor/project")
  //     },
  //     {
  //       path: "/esop/resource",
  //       name: "editorResource",
  //       meta: {
  //         title: "Resource - 资源"
  //       },
  //       component: () => import("@/views/ar-editor/resource")
  //     }
  //   ]
  // },
  {
    path: "/404",
    name: "error_404",
    component: () => import("@/views/error-page/404.vue")
  },

  {
    path: "*", // 页面不存在的情况下会跳到404页面
    redirect: "/404",
    name: "notFound",
    hidden: true
  }
];
