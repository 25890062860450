import axios from "@/libs/request/api.request";
export const queryDevices = data => {
  return axios.request({
    url: "/rs/lives/device/list",
    params: data,
    method: "get"
  });
};

export const addDevice = data => {
  return axios.request({
    url: "/rs/lives/device/create",
    data,
    method: "post"
  });
};

export const queryDeviceDesc = data => {
  return axios.request({
    url: "/rs/lives/devices/" + data.deviceId,
    method: "get"
  });
};

export const modDevice = data => {
  return axios.request({
    url: `/rs/lives/device/update/${data.id}`,
    data,
    method: "post"
  });
};

export const setDeviceStatus = data => {
  return axios.request({
    url: "/rs/lives/devices/" + data.get("deviceId") + "/status",
    data,
    method: "put"
  });
};

export const delDevice = id => {
  return axios.request({
    url: `/rs/lives/device/delete/${id}`,
    method: "post",
  });
};

export const getConfig = () => {
  return axios.request({
    url: "/rs/lives/config",
    method: "get"
  });
};
