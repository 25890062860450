import {
  getToken,
  localSave,
  localRead,
  setToken,
  removeToken
} from "@/libs/util";
import { checkToken, login, logOut } from "@/api/common/user";

export default {
  state: {
    token: getToken(),
    userInfo: localRead("userInfo") ? JSON.parse(localRead("userInfo")) : []
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      setToken(token);
    },
    removeToken(state) {
      state.token = "";
      removeToken();
    },
    setUserInfo(state, data) {
      state.userInfo = data;
      localSave("userInfo", JSON.stringify(data));
    }
  },
  getters: {},
  actions: {
    handleLogin({ commit }, data) {
      return new Promise((resolve, reject) => {
        login(data)
          .then(res => {
            if (res.code === 200) {
              commit("setToken", res.data.token);
              commit("setUserInfo", res.data.user);
            }
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    handleLogOut({ commit }) {
      return new Promise(resolve => {
        logOut().then(res => {
          if (res.code === 200) {
            commit("removeToken", "");
            resolve(res);
          }
        });
      });
    },
    handleCheckToken({ commit }) {
      return new Promise((resolve, reject) => {
        checkToken()
          .then(res => {
            console.log(res)
            if (res.code === 200) {
              commit("setUserInfo", res.data);
            }
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  }
};
