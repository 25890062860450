<template>
  <div class="live-device" v-loading="loading">
    <div class="live-device-title">
      <el-row>
        <el-col :span="12" align="left">
          <el-input
            style="width: 300px"
            v-model="keyword"
            @input="queryDataByIndex"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i
          ></el-input>
        </el-col>
        <el-col :span="12" align="right">
          <button
            @click="showAddDialog = true"
            type="button"
            class="m-btn-live-add"
          >
            <i class="el-icon-plus"></i> 添 加
          </button>
        </el-col>
      </el-row>
    </div>
    <div class="live-device-content">
      <el-table
        :data="tableData"
        style="width: 100%"
        header-cell-class-name="headerClass"
      >
        <el-table-column prop="serialNumber" label="设备编号" align="center">
        </el-table-column>
        <el-table-column prop="name" label="设备名称" align="center">
        </el-table-column>
        <el-table-column prop="rtmpUrl" label="推流地址" align="center">
        </el-table-column>
        <el-table-column prop="type" label="设备类型" align="center">
          <template slot-scope="scope">
            <div>
              <p>{{ deviceTypeList[scope.row.type] }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="录制状态" align="center">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.record"
              :active-value="activeValue"
              :inactive-value="inactiveValue"
              @change="handleRecord(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <!--            <el-button size="mini" @click="handleDesc(scope.row)"-->
            <!--              >详情</el-button-->
            <!--            >-->
            <button
              type="button"
              @click="showModify(scope.row)"
              class="m-btn-live-modify"
            >
              <i class="font_family m-icon-modify"></i>
            </button>
            <!--            <el-button size="mini" @click="handleStatus(scope.row)">{{-->
            <!--              scope.row.status ? "启用" : "禁用"-->
            <!--            }}</el-button>-->
            <button
              type="button"
              @click="handleDelete(scope.row)"
              class="m-btn-live-delete"
            >
              <i class="font_family m-icon-delete"></i>
            </button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="live-device-footer">
      <el-pagination
        background
        layout="total,prev, pager, next"
        :current-page="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.totalNum"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <el-dialog title="新增" :visible.sync="showAddDialog" width="650px">
      <add-device @close="closeAdd" v-if="showAddDialog"></add-device>
    </el-dialog>
    <el-dialog title="修改" :visible.sync="showModifyDialog" width="650px">
      <modify-device
        @close="closeModify"
        v-if="showModifyDialog"
        :data="modifyData"
      ></modify-device>
    </el-dialog>
  </div>
</template>

<script>
import {
  delDevice,
  getConfig,
  queryDeviceDesc,
  queryDevices,
  setDeviceStatus,
  modDevice
} from "@/api/live/device";
import AddDevice from "@/views/vr-live/device/components/AddDevice";
import ModifyDevice from "@/views/vr-live/device/components/ModifyDevice";
import { deviceTypeList } from "@//libs/dict/live";
export default {
  name: "device",
  components: { ModifyDevice, AddDevice },
  data() {
    return {
      loading: true,
      keyword: "",
      tableData: [],
      liveConf: "",
      activeValue: 1,
      inactiveValue: 0,
      pageInfo: {
        currentPage: 1,
        pageSize: 5,
        totalNum: 0
      },
      showAddDialog: false,
      showModifyDialog: false,
      modifyData: [],
      deviceTypeList
    };
  },
  mounted() {
    // this.initConfig();
    this.queryDataByIndex();
  },
  methods: {
    initConfig() {
      getConfig().then(res => {
        if (res.code === 200) {
          this.liveConf = res.data;
        }
        this.queryDataByIndex();
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        // eslint-disable-next-line no-unused-vars
        .then(_ => {
          done();
        })
        // eslint-disable-next-line no-unused-vars
        .catch(_ => {});
    },
    handleCurrentChange(val) {
      if (this.pageInfo.currentPage !== val) {
        this.pageInfo.currentPage = val;
        this.queryDataByIndex();
      }
    },
    queryDataByIndex() {
      let data = {
        pageNum: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize,
        keyword: this.keyword
      };
      queryDevices(data).then(res => {
        if (res.code === 200) {
          if (res.data.list.length > 0) {
            this.tableData = res.data.list;
            this.pageInfo.totalNum = res.data.total;
          } else {
            if (this.pageInfo.currentPage > 1) {
              this.pageInfo.currentPage--;
              this.queryByPageIndex();
            } else {
              this.tableData = [];
              this.pageInfo.totalNum = 0;
            }
          }
        } else {
          this.$message.warning(res.msg);
          this.tableData = [];
          this.pageInfo.total = 0;
        }
        this.loading = false;
        console.log(this.tableData);
      });
    },

    closeAdd() {
      this.queryDataByIndex();
      this.showAddDialog = false;
    },
    showModify(row) {
      this.modifyData = row;
      this.showModifyDialog = true;
    },
    closeModify() {
      this.queryDataByIndex();
      this.showModifyDialog = false;
    },
    handleRecord(item) {
      modDevice(item).then((res) => {
        if (res.code === 200) {
          this.$message.success("修改成功");
        } else {
          this.$message.warning(res.msg);
        }
        this.queryDataByIndex();
      });
    },
    handleStatus(row) {
      let data = new FormData();
      data.append("deviceId", row.id);
      data.append("status", row.status);
      setDeviceStatus(data).then(res => {
        if (res.code === 200) {
          this.queryDataByIndex();
        } else {
          this.$message.warning(res.msg);
          this.queryDataByIndex();
        }
      });
    },
    handleDesc(row) {
      let data = {
        deviceId: row.id
      };
      queryDeviceDesc(data).then(res => {
        console.log(res);
      });
    },
    handleDelete(row) {
      let _this = this;
      this.$confirm("此操作将删除该设备, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delDevice(row.id).then(res => {
          if (res.code === 200) {
            _this.queryDataByIndex();
          } else {
            _this.$message.warning(res.msg);
          }
        });
      });
    }
  }
};
</script>

<style scoped></style>
