<template>
  <div class="wap-page">
    <div class="home" align="center">
      <the-header></the-header>
      <div>
        <img
          src="../../../assets/images/wap-page/home/banner.png"
          style="width: 100%"
          alt=""
        />
      </div>
      <div class="home-information">
        <div style="width: 92%" align="left">
          <div class="title1" align="center">
            <p>行业资讯</p>
          </div>
          <div class="home-information-item">
            <div class="title2">
              <p>
                5G+AR助力行业数字化浪潮的加速到来
              </p>
            </div>
            <div class="text">
              <p>
                2019年6月，国家工信部向三大运营商正式颁发5G牌照，标志着我国正式进入5G时代。5G作为跨时代技术，相较于4G网络，不仅可以提供良好的用户体验和较高的网络容量，还将开启万物互联的时代，同时渗透至千行百业。其中AR技术由于5G的加持，众多应用场景成功落地。运营商利用品牌和渠道优势，以5G为契机，整合合作伙伴的优质技术资源，快速切入市场，逐步参与构建基于AR技术的应用生态体系。
              </p>
            </div>
            <div class="img">
              <img
                src="../../../assets/images/wap-page/home/5G1.png"
                alt=""
                style="width: 100%"
              />
            </div>
          </div>
          <div class="home-information-item">
            <div class="title2">
              <p>
                5G中央空调应用示范基地揭牌仪式
              </p>
            </div>
            <div class="text">
              <p>
                海尔中央空调智能互联工厂作为“灯塔工厂”及5G中央空调应用示范基地,由海尔联合中国信通院、中国家电院、中国移动通信集团有限公司、华为技术有限公司与极视云科技共同打造。
                互联工厂落地了AR远程协作、AR作业指导、AR巡检可视化、人脸识别等功能。
              </p>
            </div>
            <div class="img">
              <img
                src="../../../assets/images/wap-page/home/5G2.png"
                alt=""
                style="width: 100%"
              />
            </div>
          </div>
          <div class="home-information-item">
            <div class="title2">
              <p>5G+AR赋能智慧医疗</p>
            </div>
            <div class="text">
              <p>
                在以往，不同地区的医生之间主要是依靠微信等方法传递视频、图片或者语音，很难做到同步，在与生命的赛跑中很可能会出现“慢半拍“的情况。5G+AR技术则完美解决了这一问题。在5G网络加持之下，手术现场医生可以通过AR智能眼镜的第一视角功能将视频快速传递到后端专家的屏幕上，并结合心电监护视频等其他影像，给予后端专家全面的信息支持。
                不仅如此，通过5G+AR技术，可以帮助将大医院的技术能力下沉辐射到更广泛区域。在新技术的帮助下，可以有效助推分级医疗制度，缓解广大群众就医难的问题。
              </p>
            </div>
            <div class="img">
              <img
                src="../../../assets/images/wap-page/home/5G3.png"
                alt=""
                style="width: 100%"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="home-cloud">
        <div style="width: 92%">
          <div class="title1">
            <p>
              AIR CLOUD-平台服务
            </p>
          </div>
          <div align="left" class="text" style="text-align: center">
            <p>
              上海极视云是从事与传感器结合和计算机视觉技术研究的科技公司。主要产业是为以AR智能眼镜为代表的智能硬件提供相应的软件应用及解决方案。目前应用方向是针对垂直市场产生的“解放双手，第一视角”的可穿戴应用需求，结合智能眼镜硬件方案形成完整的操作系统，从云到端的应用软件的解决方案。
            </p>
          </div>
          <div>
            <el-row>
              <el-col :span="6">
                <div>
                  <img
                    src="../../../assets/images/wap-page/home/product/product1.png"
                    alt=""
                  />
                </div>
                <div>
                  <p>
                    远程协作
                  </p>
                </div>
              </el-col>
              <el-col :span="6">
                <div>
                  <img
                    src="../../../assets/images/wap-page/home/product/product2.png"
                    alt=""
                  />
                </div>
                <div>
                  <p>
                    人机交互
                    <!--                  软件-->
                  </p>
                </div></el-col
              >
              <el-col :span="6">
                <div>
                  <img
                    src="../../../assets/images/wap-page/home/product/product3.png"
                    alt=""
                  />
                </div>
                <div>
                  <p>
                    上层应用
                    <!--                  软件-->
                  </p>
                </div></el-col
              >
              <el-col :span="6">
                <div>
                  <img
                    src="../../../assets/images/wap-page/home/product/product4.png"
                    alt=""
                  />
                </div>
                <div>
                  <p>
                    AR-AI算法
                    <!--                  识别系统-->
                  </p>
                </div></el-col
              >
            </el-row>
          </div>
        </div>
      </div>
      <div style="width: 95%;padding-top: 2rem;height: 15rem">
        <div class="title1">
          <p>
            解决方案
          </p>
        </div>
        <div style="padding-top: 0.5rem">
          <div class="home-solution" style="">
            <div
              class="home-solution-card"
              style=""
              v-for="(item, index) in solutionList"
              :key="index"
              @click="gotoSolution"
            >
              <img
                :src="item.backUrl"
                style="height: 10rem;width:7.8rem"
                alt=""
              />
              <div style="margin-top: -8rem">
                <img
                  :src="item.iconUrl"
                  style="height: 6rem;width:6rem"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <the-footer></the-footer>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import TheFooter from "@/components/wap-page/TheFooter";
import TheHeader from "@/components/wap-page/TheHeader";
import { solutionList } from "../../../libs/dict/wap-page.js";

export default {
  name: "Home",
  components: { TheHeader, TheFooter },
  data() {
    return {
      solutionList
    };
  },
  methods: {
    gotoSolution() {
      this.$router.push({
        name: "wapSolution"
      });
    }
  }
};
</script>
<style scoped></style>
