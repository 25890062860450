export const productList = [
  {
    imgUrl: require("../../assets/images/websites/home/remote.png"),
    name: "远程协助",
    routerName: "product",
    type: 1
  },
  {
    imgUrl: require("../../assets/images/websites/home/type2.png"),
    name: "人机交互软件",
    routerName: "product",
    type: 2
  },
  {
    imgUrl: require("../../assets/images/websites/home/type3.png"),
    name: "云端管理平台",
    routerName: "product",
    type: 3
  },
  {
    imgUrl: require("../../assets/images/websites/home/type4.png"),
    name: "AR-AI算法识别系统",
    routerName: "product",
    type: 4
  }
];

export const solutionList = [
  {
    backUrl: require("../../assets/images/websites/solution/types/1/back.png"),
    iconUrl: require("../../assets/images/websites/solution/types/1/made.png"),
    text: "通过智能眼镜进行电子手册、视频查阅",
    icon2Url: require("../../assets/images/websites/solution/types/1/made1.png"),
    icon3Url: require("../../assets/images/websites/solution/types/1/made2.png"),
    routerName: "solutionDesc",
    name: "智能制造",
    type: 1
  },
  {
    backUrl: require("../../assets/images/websites/solution/types/2/back.png"),
    iconUrl: require("../../assets/images/websites/solution/types/2/medical.png"),
    text: "通过眼镜远程协助功能来对病人进行实时的诊断及康复建议",
    icon2Url: require("../../assets/images/websites/solution/types/2/medical1.png"),
    icon3Url: require("../../assets/images/websites/solution/types/2/medical2.png"),
    routerName: "solutionDesc",
    name: "智慧医疗",
    type: 2
  },
  {
    backUrl: require("../../assets/images/websites/solution/types/3/back.png"),
    iconUrl: require("../../assets/images/websites/solution/types/3/showroom.png"),
    text: "新型传授方式区别个体化差异、环境复杂化，高效完成技能培训",
    icon2Url: require("../../assets/images/websites/solution/types/3/showroom1.png"),
    icon3Url: require("../../assets/images/websites/solution/types/3/showroom2.png"),
    routerName: "solutionDesc",
    name: "智慧展厅",
    type: 3
  },
  {
    backUrl: require("../../assets/images/websites/solution/types/4/back.png"),
    iconUrl: require("../../assets/images/websites/solution/types/4/security.png"),
    text: "实现眼镜穿戴式使得执法人员可随意活动，有效执法",
    icon2Url: require("../../assets/images/websites/solution/types/4/security1.png"),
    icon3Url: require("../../assets/images/websites/solution/types/4/security2.png"),
    routerName: "solutionDesc",
    name: "智慧安防",
    type: 4
  },
  {
    backUrl: require("../../assets/images/websites/solution/types/5/back.png"),
    iconUrl: require("../../assets/images/websites/solution/types/5/journey.png"),
    text: "通过智能眼镜实现整个维修指导视频存储至云端，便于后期调取查看与学习",
    icon2Url: require("../../assets/images/websites/solution/types/5/journey1.png"),
    icon3Url: require("../../assets/images/websites/solution/types/5/journey2.png"),
    routerName: "solutionDesc",
    name: "智慧文旅",
    type: 5
  },
  {
    backUrl: require("../../assets/images/websites/solution/types/6/back.png"),
    iconUrl: require("../../assets/images/websites/solution/types/6/education.png"),
    text: "通过眼镜实现远程的教育培训，同 时可将学习资源存储云端",
    icon2Url: require("../../assets/images/websites/solution/types/6/education1.png"),
    icon3Url: require("../../assets/images/websites/solution/types/6/education2.png"),
    routerName: "solutionDesc",
    name: "智慧教育",
    type: 6
  }
];

export const solutionData = [
  {
    title: "智能制造",
    types: [
      {
        title: "作业指导",
        typeKey: 1,
        typeIcon: require("../../assets/images/websites/solution/types/1/types/1/img.png"),
        activeIcon: require("../../assets/images/websites/solution/types/1/types/1/active.png"),
        typeDesc: require("../../assets/images/websites/solution/types/1/types/1/desc.png")
      },
      {
        title: "运维巡检",
        typeKey: 2,
        typeIcon: require("../../assets/images/websites/solution/types/1/types/2/img.png"),
        activeIcon: require("../../assets/images/websites/solution/types/1/types/2/active.png"),
        typeDesc: require("../../assets/images/websites/solution/types/1/types/2/desc.png")
      },
      {
        title: "物资管理",
        typeKey: 3,
        typeIcon: require("../../assets/images/websites/solution/types/1/types/3/img.png"),
        activeIcon: require("../../assets/images/websites/solution/types/1/types/3/active.png"),
        typeDesc: require("../../assets/images/websites/solution/types/1/types/3/desc.png")
      },
      {
        title: "数据可视化",
        typeKey: 4,
        typeIcon: require("../../assets/images/websites/solution/types/1/types/4/img.png"),
        activeIcon: require("../../assets/images/websites/solution/types/1/types/4/active.png"),
        typeDesc: require("../../assets/images/websites/solution/types/1/types/4/desc.png")
      }
    ]
  },
  {
    title: "智慧医疗",
    types: [
      {
        title: "紧急救护",
        typeKey: 5,
        typeIcon: require("../../assets/images/websites/solution/types/1/types/5/img.png"),
        activeIcon: require("../../assets/images/websites/solution/types/1/types/5/active.png"),
        typeDesc: require("../../assets/images/websites/solution/types/1/types/5/desc.png")
      },
      {
        title: "手术直播与示教",
        typeKey: 6,
        typeIcon: require("../../assets/images/websites/solution/types/1/types/6/img.png"),
        activeIcon: require("../../assets/images/websites/solution/types/1/types/6/active.png"),
        typeDesc: require("../../assets/images/websites/solution/types/1/types/6/desc.png")
      },
      {
        title: "远程诊疗",
        typeKey: 7,
        typeIcon: require("../../assets/images/websites/solution/types/1/types/7/img.png"),
        activeIcon: require("../../assets/images/websites/solution/types/1/types/7/active.png"),
        typeDesc: require("../../assets/images/websites/solution/types/1/types/7/desc.png")
      }
    ]
  },
  {
    title: "智慧展厅",
    types: [
      {
        title: "MR数字沙盘",
        typeKey: 8,
        typeIcon: require("../../assets/images/websites/solution/types/1/types/8/img.png"),
        activeIcon: require("../../assets/images/websites/solution/types/1/types/8/active.png"),
        typeDesc: require("../../assets/images/websites/solution/types/1/types/8/desc.png")
      },
      {
        title: "VR智慧党建",
        typeKey: 9,
        typeIcon: require("../../assets/images/websites/solution/types/1/types/9/img.png"),
        activeIcon: require("../../assets/images/websites/solution/types/1/types/9/active.png"),
        typeDesc: require("../../assets/images/websites/solution/types/1/types/9/desc.png")
      }
    ]
  },
  {
    title: "智慧安防",
    types: [
      {
        title: "人、车识别核查",
        typeKey: 10,
        typeIcon: require("../../assets/images/websites/solution/types/1/types/10/img.png"),
        activeIcon: require("../../assets/images/websites/solution/types/1/types/10/active.png"),
        typeDesc: require("../../assets/images/websites/solution/types/1/types/10/desc.png")
      },
      {
        title: "远程指挥调度",
        typeKey: 11,
        typeIcon: require("../../assets/images/websites/solution/types/1/types/11/img.png"),
        activeIcon: require("../../assets/images/websites/solution/types/1/types/11/active.png"),
        typeDesc: require("../../assets/images/websites/solution/types/1/types/11/desc.png")
      }
    ]
  },
  {
    title: "智慧文旅",
    types: [
      {
        title: "AR互动导览",
        typeKey: 12,
        typeIcon: require("../../assets/images/websites/solution/types/1/types/12/img.png"),
        activeIcon: require("../../assets/images/websites/solution/types/1/types/12/active.png"),
        typeDesc: require("../../assets/images/websites/solution/types/1/types/12/desc.png")
      },
      {
        title: "AR品牌推广",
        typeKey: 13,
        typeIcon: require("../../assets/images/websites/solution/types/1/types/13/img.png"),
        activeIcon: require("../../assets/images/websites/solution/types/1/types/13/active.png"),
        typeDesc: require("../../assets/images/websites/solution/types/1/types/13/desc.png")
      },
      {
        title: "AR景点复原",
        typeKey: 14,
        typeIcon: require("../../assets/images/websites/solution/types/1/types/14/img.png"),
        activeIcon: require("../../assets/images/websites/solution/types/1/types/14/active.png"),
        typeDesc: require("../../assets/images/websites/solution/types/1/types/14/desc.png")
      }
    ]
  },
  {
    title: "智慧教育",
    types: [
      {
        title: "职教培训",
        typeKey: 15,
        typeIcon: require("../../assets/images/websites/solution/types/1/types/15/img.png"),
        activeIcon: require("../../assets/images/websites/solution/types/1/types/15/active.png"),
        typeDesc: require("../../assets/images/websites/solution/types/1/types/15/desc.png")
      },
      {
        title: "安全培训",
        typeKey: 16,
        typeIcon: require("../../assets/images/websites/solution/types/1/types/16/img.png"),
        activeIcon: require("../../assets/images/websites/solution/types/1/types/16/active.png"),
        typeDesc: require("../../assets/images/websites/solution/types/1/types/16/desc.png")
      }
    ]
  }
];

export const caseList = [
  {
    name: "5G AR智慧工厂",
    text:
      "在青岛海尔空调、冰箱5G互联工厂中，极视云科技AR/VR/MR成为其中重要组成部分，应用于培训、运维等多种场景。极视云科技已与海尔智研院达成战略合作，成立了“智能穿戴装备联合研发实验室”、“虚拟现实技术工业应用联合实验室”，在智能制造多个环节展开研究与合作，共同推动AR/VR/MR在海尔及海尔生态伙伴体系中的落地。",
    imgUrl: require("../../assets/images/websites/case/5G AR智慧工厂.png"),
    iconUrl: require("../../assets/images/wap-page/case/海尔.png")
  },
  {
    name: "AR智慧仓储",
    text:
      "国家电网，其车间搭建UWB室内定位系统，可实现精准的2D、1D及区域定位功能。首先与智能眼镜结合，可实时推送线路信息，帮助作业人员完成车间导航。其次，可帮助后台管理人员实现对叉车以及车间人员的实时定位，记录其工作时的运动轨迹，完成工作效率与进度的监察，如遇突发事件，可进行科学合理的调度安排。",
    imgUrl: require("../../assets/images/websites/case/AR智慧仓储.png"),
    iconUrl: require("../../assets/images/wap-page/case/国家电网.png")
  },
  {
    name: "AR智能运维平台",
    text:
      "杭州中车以软件平台为依托，以智能化检测装备、AR穿戴设备为工具，整体构建“ 线上资源整合+线下服务投送 ”的轨道交通装备运维服务体系。通过对知识图谱、机器视觉、智能控制和自动规划等前沿技术的运用，实现平台工具各项功能与资源调配、数据分析、异常识别、故障诊断和维修管护等作业过程的紧密贴合，从而实现装备可靠性和行业人均效能的整体跃升。",
    imgUrl: require("../../assets/images/websites/case/AR智能运维平台.png"),
    iconUrl: require("../../assets/images/wap-page/case/中车.png")
  },
  {
    name: "5G AR医疗全景直播",
    text:
      "高平市人民医院借助VR探视系统，可以将前端新生儿房内全景摄像头拍摄的全景音视频单向传输至后端医生或家属，其可以通过电脑或是佩戴VR眼镜完成远程探视。远程探视过程中，医生或家属可以查看婴儿身处环境的超清画质的视频，且可以通过探视者所需查看病房内不同视角的内容。此系统可帮助医生、家属随时随地了解婴儿的情况，实现身临其境式探视，更能杜绝亲临现场探视将自身细菌传播至婴儿的隐患。",
    imgUrl: require("../../assets/images/websites/case/5G AR医疗全景直播.png"),
    iconUrl: require("../../assets/images/wap-page/case/中移.png")
  },
  {
    name: "AR智能生产线",
    text:
      "菲尼克斯电气和极视云科技合作AR智能生产线系统，通过引入人脸识别、图片像素化等AR技术，提高企业个性化服务能力。系统中AR、智能物流、智能包装、定制配送等智能体系相互配合，保障产线的智能化管控。",
    imgUrl: require("../../assets/images/websites/case/AR智能生产线.png"),
    iconUrl: require("../../assets/images/wap-page/case/菲尼克斯.png")
  },
  {
    name: "MR智慧展厅",
    text:
      "为了打破传统展厅介绍模式，向丰富生动、交互性强的智慧展厅转型，杭州正泰将MR眼镜融入展厅介绍之中，结合SLAM、3D建模、动画渲染等技术，实现了展厅在内容、模式、推广上的创新，是互联网时代新的一种传播推广方式。相比传统展厅，智慧展厅在展示时间、覆盖面、便捷性、传播速度等方面有着较大优势。",
    imgUrl: require("../../assets/images/websites/case/MR智慧展厅.png"),
    iconUrl: require("../../assets/images/wap-page/case/chint.png")
  },
  // {
  //   name: "AR智能执法",
  //   text:
  //     "2018年两会期间，北京市局结合极视云AR智能眼镜，人脸、车牌识别技术，实现了在毫秒级的时间周期内快速精准完成人脸、车牌的识别比对。协助公安实现智能高效的人员核查、指挥调度、布控协作、员工管理等，打造一条多方位、多维度、立体化的深层次感知预警防线，建设智慧机场安防新业态。",
  //   imgUrl: require("../../assets/images/websites/case/AR智能执法.png"),
  //   iconUrl: ""
  // }
];

export const remoteList = [
  {
    iconUrl: require("../../assets/images/websites/product/remote/第一视角 专家如亲临现场.png"),
    text1: "第一视角",
    text2: " 专家如亲临现场"
  },
  {
    iconUrl: require("../../assets/images/websites/product/remote/解放双手 沟通作业两不误.png"),
    text1: "解放双手",
    text2: " 沟通作业两不误"
  },
  {
    iconUrl: require("../../assets/images/websites/product/remote/多方通讯 技术支撑效率高.png"),
    text1: "多方通讯",
    text2: " 技术支撑效率高"
  },
  {
    iconUrl: require("../../assets/images/websites/product/remote/AR标记 指导重点易明确.png"),
    text1: "AR标记",
    text2: " 指导重点易明确"
  },
  {
    iconUrl: require("../../assets/images/websites/product/remote/云端存档 指导记录可追溯.png"),
    text1: "云端存档",
    text2: " 指导记录可追溯"
  },
  {
    iconUrl: require("../../assets/images/websites/product/remote/资料推送 桌面分享.png"),
    text1: "资料推送",
    text2: " 桌面分享"
  },
  {
    iconUrl: require("../../assets/images/websites/product/remote/支持云平台 与私有化部署.png"),
    text1: "支持云平台",
    text2: " 与私有化部署"
  },
  {
    iconUrl: require("../../assets/images/websites/product/remote/支持与第三 方系统集成.png"),
    text1: "支持与第三",
    text2: "方系统集成"
  }
];
