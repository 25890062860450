/**
 * 判断value是否是字典下面的一个key
 * 用法：  findValueByDist(dist, value)
 * @returns {*}
 * @param dist
 * @param value
 */
export const findValueByDist = function (dist, value) {
  let result = false;
  for (var key in dist) {
    if (dist[key] === value) {
      result = true;
    }
  }
  return result;
};
/**
 * 返回name所在路由表的那个项目中
 * 用法： getToRouterProject(routers, name)
 * @returns {*}
 * @param startTime
 * @param endTime
 */
export const getDetermine = function (startTime, endTime) {
  let date1 = new Date(startTime);
  let date2 = new Date(endTime);
  let date3 = date2.getTime() - date1.getTime();
  if (date3 > 0) {
    let days = Math.floor(date3 / (24 * 3600 * 1000));
    let leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
    let hours = Math.floor(leave1 / (3600 * 1000));
    //计算相差分钟数
    let leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
    let minutes = Math.floor(leave2 / (60 * 1000));
    //计算相差秒数
    let leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
    let seconds = Math.round(leave3 / 1000);
    if (days) {
      return (
        days + "天 " + hours + "小时 " + minutes + " 分钟" + seconds + " 秒"
      );
    } else if (hours) {
      return hours + "小时 " + minutes + " 分钟" + seconds + " 秒";
    } else if (minutes) {
      return minutes + " 分钟" + seconds + " 秒";
    } else {
      return seconds + " 秒";
    }
  } else {
    return "0秒";
  }
};

/**
 * @return {string}
 */
export const allNumType = function (val) {
  let reg = new RegExp("[0-9]"), //正则 英文+数字；
    len = val.length,
    value = "";
  for (let i = 0; i < len; i++) {
    if (reg.test(val[i])) {
      value += val[i];
    }
  }
  return value;
};

/**
 * @return {string}
 */
export const NumLettersType = function (val) {
  let reg = new RegExp("[a-zA-Z0-9]"), //正则 英文+数字；
    len = val.length,
    value = "";
  for (let i = 0; i < len; i++) {
    if (reg.test(val[i])) {
      value += val[i];
    }
  }
  return value;
};
/**
 * @return {string}
 */
export const NotSymbolType = function (val) {
  let reg = new RegExp("[\u4e00-\u9fa5a-zA-Z0-9]"), //正则 英文+数字；
    len = val.length,
    value = "";
  for (let i = 0; i < len; i++) {
    if (reg.test(val[i])) {
      value += val[i];
    }
  }
  return value;
};

/**
 * 返回数组中指定元素的下表，不存在返回-1
 * 用法：  getArrayIndex(obj, arr)
 * @param obj
 * @param arr
 * @return {number}
 */
export const getArrayIndex = function (obj, arr) {
  var i = arr.length;
  while (i--) {
    if (arr[i] === obj) {
      return i;
    }
  }
  return -1;
};
/**
 * 深拷贝
 * @param {*} obj
 * @returns
 */
export const cloneObj = function (obj) {
  var i;
  var o = Array.isArray(obj) ? [] : {};
  for (i in obj) {
    if (obj.hasOwnProperty(i)) {
      o[i] = typeof obj[i] === "object" ? cloneObj(obj[i]) : obj[i];
    }
  }
  return o;
};