<template>
  <div class="air-dialog" align="center">
    <div class="air-dialog-close" align="right">
      <button type="button" @click="close">×</button>
    </div>
    <div class="air-dialog-title">
      <p>个人信息</p>
    </div>
    <div class="air-dialog-content">
      <el-form :model="userForm" ref="userForm">
        <el-form-item>
          <div>
            <button class="upload" type="button"></button>
          </div>
          <div style="margin-top: 10px">
            <!--<p>-->
            <!--企业执照-->
            <!--</p>-->
          </div>
        </el-form-item>
        <el-form-item>
          <div align="left" style="width: 400px;margin-left: -80px">
            <el-row>
              <el-col :span="5">
                <p>用户姓名</p>
              </el-col>
              <el-col :span="19">
                <el-input
                  placeholder="请输入用户姓名"
                  v-model="userForm.realName"
                  :disabled="!showSave"
                ></el-input>
              </el-col>
            </el-row>
          </div>
        </el-form-item>
        <el-form-item>
          <div align="left" style="width: 400px;margin-left: -80px">
            <el-row>
              <el-col :span="5">
                <p>用户账号</p>
              </el-col>
              <el-col :span="19">
                <el-input
                  placeholder="请输入用户账号"
                  v-model="userForm.userName"
                  disabled
                ></el-input>
              </el-col>
            </el-row>
          </div>
        </el-form-item>
        <el-form-item>
          <div align="left" style="width: 400px;margin-left: -80px">
            <el-row>
              <el-col :span="5">
                <p>手机号码</p>
              </el-col>
              <el-col :span="19">
                <el-input
                  placeholder="请输入手机号码"
                  v-model="userForm.mobile"
                  disabled
                ></el-input>
              </el-col>
            </el-row>
          </div>
        </el-form-item>
        <el-form-item>
          <div align="left" style="width: 400px;margin-left: -80px">
            <el-row>
              <el-col :span="5">
                <p>用户类型</p>
              </el-col>
              <el-col :span="19">
                <el-input
                  placeholder="请输入用户类型"
                  :value="
                    userForm.roleId === 1
                      ? '超级管理员'
                      : userForm.roleId === 2
                      ? '企业管理员'
                      : userForm.roleId === 3
                      ? '个人用户'
                      : '企业用户'
                  "
                  disabled
                ></el-input>
              </el-col>
            </el-row>
          </div>
        </el-form-item>
        <el-form-item>
          <div align="left" style="width: 400px;margin-left: -80px">
            <el-row>
              <el-col :span="5">
                <p>用户邮箱</p>
              </el-col>
              <el-col :span="19">
                <el-input
                  placeholder="请输入用户邮箱"
                  v-model="userForm.email"
                  :disabled="!showSave"
                ></el-input>
              </el-col>
            </el-row>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="air-dialog-footer">
      <button
        type="button"
        @click="showSave = true"
        v-show="!showSave"
        class="air-btn-mod"
      >
        修改
      </button>
      <button
        type="button"
        @click="save"
        v-show="showSave"
        class="air-btn-save"
      >
        保存
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserInfo",
  data() {
    return {
      showSave: false,
      userForm: {
        userId: this.$store.state.user.userInfo.userId,
        realName: this.$store.state.user.userInfo.realName,
        userName: this.$store.state.user.userInfo.userName,
        mobile: this.$store.state.user.userInfo.mobile,
        roleId: this.$store.state.user.userInfo.roleId,
        email: this.$store.state.user.userInfo.email
      }
    };
  },
  methods: {
    save() {
      this.$refs.userForm.validate(valid => {
        if (valid) {
          let data = {
            userId: this.userForm.userId,
            realName: this.userForm.realName,
            userName: this.userForm.userName,
            mobile: this.userForm.mobile,
            email: this.userForm.email
          };
          this.$emit("save", data);
        }
      });
    },
    close() {
      this.$emit("close", {});
    }
  }
};
</script>

<style scoped></style>
