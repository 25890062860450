<template>
  <div class="wap-page">
    <div class="case" align="center">
      <the-header></the-header>
      <div>
        <img
                src="../../../assets/images/wap-page/case/banner.png"
                alt=""
                style="width: 100%"
        />
      </div>
      <div align="center" style="width: 92%">
        <div>
          <div
                  v-for="(item, index) in caseList"
                  :key="index"
                  style="margin-top: 0.6rem"
          >
            <div>
              <div>
                <el-row>
                  <el-col :span="16">
                    <div class="title2" align="left">
                      <p>{{ item.name }}</p>
                    </div>
                  </el-col>
                  <el-col :span="8" align="right">
                    <div class="icon">
                      <img :src="item.iconUrl" alt="" style="height:1.7rem" />
                    </div>
                  </el-col>
                </el-row>
              </div>

              <div class="text" align="left">
                <p>
                  {{ item.text }}
                </p>
              </div>

              <div class="img">
                <img :src="item.imgUrl" alt="" style="width: 100%" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <the-footer></the-footer>
    </div>
  </div>

</template>

<script>
import TheHeader from "@/components/wap-page/TheHeader";
import { caseList } from "@/libs/dict/wap-page.js";
import TheFooter from "@/components/wap-page/TheFooter";

export default {
  name: "Case",
  components: { TheFooter, TheHeader },
  data() {
    return {
      caseList
    };
  }
};
</script>

<style scoped></style>
