import Axios from "axios";
import store from "@/store";
import ElementUI from "element-ui";
import router from "../../router";
// import { Spin } from 'iview'
const addErrorLog = errorInfo => {
  const {
    statusText,
    status,
    request: { responseURL }
  } = errorInfo;
  let info = {
    type: "ajax",
    code: status,
    mes: statusText,
    url: responseURL
  };
  if (status === 503) {
    ElementUI.Notification.error({
      title: "异常",
      duration: 3000,
      message: "服务不可用，请联系管理员"
    });
  }

  console.log(info);
  // if (!responseURL.includes("save_error_logger"))
  //   store.dispatch("addErrorLog", info);
};

class HttpRequest {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.queue = {};
  }
  getInsideConfig() {
    return {
      baseURL: this.baseUrl
    };
  }
  destroy(url) {
    delete this.queue[url];
    if (!Object.keys(this.queue).length) {
      // Spin.hide()
    }
  }
  interceptors(instance, url) {
    // 请求拦截
    instance.interceptors.request.use(
      config => {
        // 添加全局的loading...
        if (!Object.keys(this.queue).length) {
          // Spin.show() // 不建议开启，因为界面不友好
        }
        this.queue[url] = true;
        config.headers = {
          token: store.state.user.token
        };
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
    // 响应拦截
    instance.interceptors.response.use(
      res => {
        // console.log("进入正常");
        this.destroy(url);
        return res.data;
      },
      error => {
        // console.log("进入error");
        this.destroy(url);
        let errorInfo = error.response;
        if (!errorInfo) {
          const {
            request: { statusText, status },
            config
          } = JSON.parse(JSON.stringify(error));
          errorInfo = {
            statusText,
            status,
            request: { responseURL: config.url }
          };
        }

        {
          addErrorLog(errorInfo);
          return Promise.reject(error);
        }
      }
    );
  }
  request(options) {
    const instance = Axios.create();
    options = Object.assign(this.getInsideConfig(), options);
    this.interceptors(instance, options.url);
    return instance(options);
  }
}
export default HttpRequest;
