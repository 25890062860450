<template>
  <div class="web-main" align="center" id="main">
    <el-backtop> </el-backtop>
    <web-header> </web-header>
    <div>
      <router-view />
    </div>
    <web-footer></web-footer>
  </div>
</template>

<script>
import WebHeader from "@/components/websites/webHeader";
import WebFooter from "@/components/websites/webFooter";
export default {
  name: "Main",
  components: { WebFooter, WebHeader },
  data() {
    return {};
  }
};
</script>

<style scoped lang="less"></style>
