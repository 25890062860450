<template>
  <div class="wap-page">
    <div class="about" align="center">
      <the-header></the-header>
      <div>
        <img
          src="../../../assets/images/wap-page/about/banner.png"
          style="width: 100%"
          alt=""
        />
      </div>
      <div>
        <div>
          <div style="width: 92%;padding-bottom: 2rem">
            <div class="title1">
              <p>公司资质</p>
            </div>
            <div class="text">
              <p>
                上海极视云信息科技有限公司以XR智能眼镜为切入点，结合多种传感器方案，面向工业互联网及智能制造，打造以AI+AR为核心的智慧空间生态云平台。在协同设计，远程协助，数字可视化等场景有多个成功案例。
              </p>
            </div>
          </div>
<!--          <div-->
<!--            style="background-color: #2c3e50;padding-top: 1rem;height: 15rem"-->
<!--          >-->
<!--            <div class="title2">-->
<!--              <p>-->
<!--                资格证书-->
<!--              </p>-->
<!--            </div>-->
<!--            -->
<!--            <div>-->
<!--              <div>-->
<!--                <div>-->
<!--                  <div style=" width: 98%">-->
<!--                    <div>-->
<!--                      <div class="certificate" style="">-->
<!--                        <div-->
<!--                          class="certificate-item"-->
<!--                          v-for="(item, index) in certificateList"-->
<!--                          :key="index"-->
<!--                          @click="openImg(item.img)"-->
<!--                          align="center"-->
<!--                        >-->
<!--                          <div class="certificate-item-img">-->
<!--                            <img :src="item.img" alt="" />-->
<!--                          </div>-->
<!--                          <div class="certificate-item-text">-->
<!--                            <p>{{ item.text }}</p>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div style="width: 92%;padding-top: 2rem">-->
<!--            <div class="title1">-->
<!--              <p>服务模式</p>-->
<!--            </div>-->
<!--            <div class="content">-->
<!--              <p>-->
<!--                协助客户快速了解，部署平台并提供相应的培训与技术支持。对待特殊需求可做进一步的定制化开发-->
<!--              </p>-->
<!--            </div>-->
<!--            <div>-->
<!--              <el-row>-->
<!--                <el-col :span="8">-->
<!--                  <div>-->
<!--                    <img-->
<!--                      src="../../../assets/images/wap-page/about/初级.png"-->
<!--                      alt=""-->
<!--                      style="width: 95%"-->
<!--                    />-->
<!--                  </div>-->
<!--                </el-col>-->
<!--                <el-col :span="8">-->
<!--                  <div>-->
<!--                    <img-->
<!--                      src="../../../assets/images/wap-page/about/中级.png"-->
<!--                      alt=""-->
<!--                      style="width: 95%"-->
<!--                    />-->
<!--                  </div>-->
<!--                </el-col>-->
<!--                <el-col :span="8">-->
<!--                  <div>-->
<!--                    <img-->
<!--                      src="../../../assets/images/wap-page/about/高级.png"-->
<!--                      alt=""-->
<!--                      style="width: 95%"-->
<!--                    />-->
<!--                  </div>-->
<!--                </el-col>-->
<!--              </el-row>-->
<!--            </div>-->
<!--          </div>-->
          <div style="padding-top: 2rem">
            <div class="title1">
              <p>合作伙伴</p>
            </div>
            <div style="width: 92%">
              <img
                src="../../../assets/images/wap-page/about/合作伙伴.png"
                alt=""
                style="width: 100%"
              />
            </div>
          </div>
<!--          <div style="padding-top: 3rem">-->
<!--            <img-->
<!--              src="../../../assets/images/wap-page/about/联系我们.png"-->
<!--              alt=""-->
<!--              style="width: 100%"-->
<!--            />-->
<!--          </div>-->
        </div>
      </div>
      <the-footer></the-footer>

      <el-dialog title="资格证书" :visible.sync="showImg" width="100%">
        <div>
          <img :src="imgUrl" alt="" width="100%" />
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import TheFooter from "@/components/wap-page/TheFooter";
import { certificateList } from "../../../libs/dict/wap-page.js";
import TheHeader from "@/components/wap-page/TheHeader";
// import TheDialog from "@/components/TheDialog";

export default {
  name: "About",
  components: { TheHeader, TheFooter },
  data() {
    return {
      certificateList,
      imgUrl: "",
      showImg: false
    };
  },
  mounted() {
    // this.certificateData = JSON.parse(JSON.stringify(this.certificateList));
    // this.carousel();
  },
  methods: {
    openImg(url) {
      this.imgUrl = url;
      this.showImg = true;
    }
    // carousel() {
    //   let _this = this;
    //   this.certificateData.push(_this.certificateData.shift());
    //   setTimeout(this.carousel, 3000);
    // }
  }
};
</script>

<style scoped></style>
