export default {
  public: {
    cancel: "Cancel",
    clear: "Clear",
    confirm: "OK",
    prompt: "Prompt",
    delete: "Delete",
    close: "Close",
    return: "Return",
    query: "Query",
    operation: "Operation",
    details: "Details",
    modify: "Modify",
    login: "Login",
    keep: "Keep Password"
  },
  menu: {
    live: "Live Management",
    device: "Device Management"
  }
};
