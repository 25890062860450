<template>
  <div class="web-header">
    <el-row>
      <el-col :span="4">
        <div style="padding: 10px 20px">
          <img
            src="../../assets/images/websites/logo.png"
            alt=""
            style="height: 40px;"
          />
        </div>
      </el-col>
      <el-col :span="20" align="right" style="padding-right: 20px">
        <div
          class="web-header-content-item"
          v-for="item in menus"
          :key="item.routerName"
          align="center"
        >
          <button
            type="button"
            @click="goto(item.routerName)"
            :class="currentRouteName === item.routerName ? 'button-active':'button'"
          >
            {{ item.label }}
          </button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "webHeader",

  data() {
    return {
      currentRouteName: "",
      menus: [
        {
          label: "首页",
          routerName: "home"
        },
        {
          label: "产品介绍",
          routerName: "product"
        },
        {
          label: "解决方案",
          routerName: "solution"
        },
        {
          label: "客户案例",
          routerName: "case"
        },
        {
          label: "关于我们",
          routerName: "about"
        }
      ]
    };
  },
  watch: {
    $route: function() {
      this.currentRouteName = this.$route.name;
    }
  },
  mounted() {
    this.currentRouteName = this.$route.name;
  },
  methods: {
    goto(routerName) {
      this.$router.push({
        name: routerName
      });
    }
  }
};
</script>

<style scoped lang="less"></style>
