import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routers";
import { getToken, setToken } from "@/libs/util";
import store from "@/store";
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes
});

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err);
};

const noNeedCheckRouter = [
  "home",
  "product",
  "solution",
  "solutionDesc",
  "case",
  "about",
  "airLogin",
  "airRegister",
  "wapHome",
  "wapProduct",
  "wapSolution",
  "wapCase",
  "wapAbout",
  "liveLogin",
  "error_404",
  "test"
];
const webPage = [
  "home",
  "product",
  "solution",
  "solutionDesc",
  "case",
  "about"
];
const wapPage = ["wapHome", "wapProduct", "wapSolution", "wapCase", "wapAbout"];
router.beforeEach((to, from, next) => {
  const token = getToken();
  if (noNeedCheckRouter.indexOf(to.name) > -1) {
    if (webPage.indexOf(to.name) > -1) {
      if (
        /Android|webOS| iPhone | iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i.test(
          navigator.userAgent
        )
      ) {
        next({
          name: "wapHome"
        });
      } else {
        next();
      }
    } else if (wapPage.indexOf(to.name) > -1) {
      if (
        /Android|webOS| iPhone | iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i.test(
          navigator.userAgent
        )
      ) {
        next();
      } else {
        next({
          name: "home"
        });
      }
    } else {
      next();
    }
  } else {
    console.log(to.name);
    if (token) {
      store
        .dispatch("handleCheckToken")
        .then(res => {
          console.log(res);
          if (res.code === 200) {
            next();
          } else {
            if (to.name === "liveHome") {
              next({
                name: "liveLogin"
              });
            } else {
              next({
                name: "airLogin"
              });
            }
          }
        })
        .catch(err => {
          if (err.request.status === 401) {
            setToken(false);
            if (to.name === "liveHome") {
              next({
                name: "liveLogin"
              });
            } else {
              next({
                name: "airLogin"
              });
            }
          }
        });
    } else {
      if (to.name === "liveHome") {
        next({
          name: "liveLogin"
        });
      } else {
        next({
          name: "airLogin"
        });
      }
    }
  }
});

export default router;
