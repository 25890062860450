import axios from "@/libs/request/api.request";
export const queryRecords = data => {
  return axios.request({
    url: "/rs/lives/history/list",
    params: data,
    method: "get"
  });
};

export const delRecord = id => {
  return axios.request({
    url: `/rs/lives/history/delete/${id}`,
    method: "post"
  });
};
