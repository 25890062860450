<template>
  <div class="air-header" align="left">
    <div>
      <el-row>
        <el-col :span="12">
          <div class="air-header-img">
            <img
              src="../../assets/images/websites/logo.png"
              alt=""
              style="height: 40px"
              @click="gotoPage('airService')"
            />
          </div>
        </el-col>
        <el-col :span="12" align="left">
          <el-row>
            <el-col :span="21" align="right">
              <div style="height: 45px;margin-right: 20px">
                <button
                  type="button"
                  class="btn-console"
                  @click="gotoPage('serviceFace')"
                >
                  控制台</button
                >|
              </div>
            </el-col>
            <el-col :span="3" align="left">
              <div style="height: 45px">
                <button
                  type="button"
                  class="btn-user"
                  @click="openTwoMenu"
                ></button>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <div class="twoMenu">
        <el-row>
          <el-col :span="23" align="right">
            <div class="card-menu" v-if="showTwoMenu">
              <div class="user-title" @click="clickUserInfo">
                <el-row>
                  <el-col :span="6" align="left">
                    <div>
                      <img
                        src="../../assets/images/air-cloud/home/sy_touxiang.png"
                        alt=""
                        class="img-user"
                      />
                    </div>
                  </el-col>
                  <el-col :span="10" align="left">
                    <div>
                      <p class="realName">{{ userInfo.realName }}</p>
                    </div>
                    <div>
                      <p class="userName">账号:{{ userInfo.userName }}</p>
                    </div>
                  </el-col>
                  <el-col :span="8" align="right">
                    <!--<div class="div-authStatus">-->
                    <!--<button type="button" class="authStatus">已认证</button>-->
                    <!--</div>-->
                  </el-col>
                </el-row>
              </div>
              <div class="btn-menu">
                <div v-show="roleId !== 4">
                  <button
                    type="button"
                    @click="clickCompanyAuth"
                    v-show="companyAuthStatus === '0'"
                  >
                    企业认证
                  </button>
                  <button
                    type="button"
                    @click="clickModCompanyAuth"
                    v-show="companyAuthStatus === '1'"
                  >
                    认证进度
                  </button>
                  <button
                    type="button"
                    @click="gotoPage('airUser')"
                    v-show="companyAuthStatus === '2'"
                  >
                    用户管理
                  </button>
                </div>
                <div>
                  <button type="button" @click="gotoPage('airService')">
                    服务列表
                  </button>
                </div>
                <div>
                  <button type="button" @click="clickQRCode">
                    我的二维码
                  </button>
                </div>
              </div>
              <div class="logout">
                <div>
                  <button type="button" @click="logout">退出登录</button>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-dialog
      :visible="
        showCompanyAuth || showUserInfo || showQRCode || showModCompanyAuth
      "
      :show-close="false"
      width="800px"
    >
      <my-code v-if="showQRCode" @close="showQRCode = false"></my-code>
      <auth-request
        @save="companyAuthSave"
        @close="showCompanyAuth = false"
        v-if="showCompanyAuth"
      ></auth-request>
      <auth-record
        @save="companyAuthUpdate"
        @close="showModCompanyAuth = false"
        v-if="showModCompanyAuth"
        :data="companyAuditInfo"
        :record="auditRecord"
      ></auth-record>
      <user-info
        v-if="showUserInfo"
        @save="userInfoSave"
        @close="showUserInfo = false"
      ></user-info>
    </el-dialog>
  </div>
</template>

<script>
import { localRead } from "@/libs/util";
import { mapActions } from "vuex";
import {
  saveCompanyAuth,
  updateCompanyAuth,
  queryAuthByCompanyId,
  saveAuthRecord
} from "@/api/air-cloud/company.js";
import { updateUser } from "@/api/air-cloud/user.js";
import MyCode from "@/components/air-cloud/MyCode";
import AuthRequest from "@/components/air-cloud/AuthRequest";
import AuthRecord from "@/components/air-cloud/AuthRecord";
import UserInfo from "@/components/air-cloud/UserInfo";
export default {
  name: "AirHeader",
  components: { UserInfo, AuthRecord, AuthRequest, MyCode },
  data() {
    return {
      saving: false,
      showTwoMenu: false,
      showDialog: false,
      showCompanyAuth: false,
      showModCompanyAuth: false,
      showUserInfo: false,
      showQRCode: false,
      companyAuthStatus: "0",
      companyAuditInfo: {},
      auditRecord: [],
      userInfo: this.$store.state.user.userInfo
        ? this.$store.state.user.userInfo
        : [],
      roleId: this.$store.state.user.userInfo.roleId
    };
  },
  computed: {
    userInfo2: {
      get: function() {
        return JSON.parse(localRead("userInfo"));
      }
    }
  },
  mounted() {
    this.getCompanyAuthStatus();
  },
  methods: {
    ...mapActions(["handleLogOut", "handleCheckToken"]),
    logout() {
      this.handleLogOut().then(() => {
        this.$router.push({
          name: "airLogin"
        });
      });
    },
    openTwoMenu() {
      this.showTwoMenu = !this.showTwoMenu;
      if (this.showTwoMenu) {
        this.getCompanyAuthStatus();
      }
    },
    getCompanyAuthStatus() {
      let data = {
        companyId: this.$store.state.user.userInfo.companyId
      };
      queryAuthByCompanyId(data).then(res => {
        if (res.code === 200) {
          this.companyAuthStatus = res.status;
          if (res.status !== "0") {
            this.companyAuditInfo = res.companyAuditInfo;
            this.auditRecord = res.auditRecord;
            console.log(res);
          }
        }
      });
    },
    companyAuthSave(data) {
      if (!this.saving) {
        this.saving = true;
        saveCompanyAuth(data).then(res => {
          if (res.code === 200) {
            let data = {
              companyAuditId: res.data,
              status: "2",
              auditResult: "提交审核"
            };
            saveAuthRecord(data).then(response => {
              if (response.code === 200) {
                this.$message.success(
                  "企业认证申请成功，已通知审核人员进行审核"
                );
                this.getCompanyAuthStatus();
                this.showCompanyAuth = false;
                this.saving = false;
              } else {
                this.$message.error(response.msg);
                this.saving = false;
              }
            });
          } else {
            this.$message.warning(res.msg);
            console.log(res);
            this.saving = false;
          }
        });
      }
    },
    companyAuthUpdate(data) {
      if (!this.saving) {
        this.saving = true;
        updateCompanyAuth(data).then(res => {
          if (res.code === 200) {
            let data2 = {
              companyAuditId: data.companyAuditId,
              status: "2",
              auditResult: "修改后再次提交审核"
            };
            saveAuthRecord(data2).then(response => {
              if (response.code === 200) {
                this.$message.success(
                  "企业认证修改成功，已通知审核人员进行审核"
                );
                this.getCompanyAuthStatus();
                this.showModCompanyAuth = false;
                this.saving = false;
              } else {
                this.$message.error(response.msg);
                this.saving = false;
              }
            });
          } else {
            this.$message.warning(res.msg);
            console.log(res);
            this.saving = false;
          }
        });
      }
    },
    userInfoSave(data) {
      if (!this.saving) {
        this.saving = true;
        updateUser(data).then(res => {
          if (res.code === 200) {
            this.$message.success("修改用户信息成功");
            this.handleCheckToken().then(response => {
              if (response.code === 200) {
                this.userInfo = this.$store.state.user.userInfo;
                console.log(response);
              }
            });
            this.showUserInfo = false;
            this.saving = false;
          } else {
            this.$message.warning(res.msg);
            this.saving = false;
            console.log(res);
          }
        });
      }
    },

    clickUserInfo() {
      this.showTwoMenu = false;
      this.showUserInfo = true;
    },
    clickQRCode() {
      this.showTwoMenu = false;

      this.showQRCode = true;
    },
    clickCompanyAuth() {
      this.showTwoMenu = false;
      this.showCompanyAuth = true;
    },
    clickModCompanyAuth() {
      this.showTwoMenu = false;
      this.showModCompanyAuth = true;
    },
    gotoPage(name) {
      this.$router.push({
        name: name
      });
    }
  }
};
</script>

<style scoped></style>
