import axios from "@/libs/request/api.request";
export const login = data => {
  return axios.request({
    url: "/auth/login",
    data,
    method: "post"
  });
};

export const logOut = () => {
  return axios.request({
    url: "/auth/logout",
    method: "post"
  });
};

export const checkToken = () => {
  return axios.request({
    url: "/system/users/check",
    method: "get"
  });
};

export const register = data => {
  return axios.request({
    url: "/auth/register",
    data,
    method: "post"
  });
};

export const checkMobile = data => {
  return axios.request({
    url: "/auth/checkMobile",
    data,
    method: "post"
  });
};

// 验证码
export const sendCode = data => {
  return axios.request({
    url: "/auth/code",
    data,
    method: "post"
  });
};

//
export const sendForgetCode = data => {
  return axios.request({
    url: "/auth/reset",
    data,
    method: "post"
  });
};
export const saveNewPassword = data => {
  return axios.request({
    url: "/auth/resetPassword",
    data,
    method: "post"
  });
};
export const activeTrialUser = data => {
  return axios.request({
    url: "/auth/activeUser",
    data,
    method: "post"
  });
};
