<template>
  <div class="Aside">
    <div>
      <div class="m-menu">
        <div
          class="m-menu-item"
          v-for="item in roleId === 1
            ? data
            : roleId === 3
            ? data3
            : roleId === 2
            ? data2
            : data4"
          :key="item.name"
        >
          <div
            :class="
              item.name === currentRouteName ? item.activeClass : item.class
            "
            @click="goto(item.name)"
          >
            <p>
              {{ item.label }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AirAside",
  data() {
    return {
      roleId: this.$store.state.user.userInfo.roleId,
      currentRouteName: "",
      data: [
        {
          name: "serviceAuth",
          class: "m-icon-auth",
          activeClass: "m-icon-auth-active",
          label: "认证审核"
        },
        {
          name: "serviceOrder",
          class: "m-icon-auth",
          activeClass: "m-icon-auth-active",
          label: "订单管理"
        },
        {
          name: "airUser",
          class: "m-icon-company",
          activeClass: "m-icon-company-active",
          label: "用户管理"
        },
        // {
        //   name: "RemoteServer",
        //   class: "m-icon-remote",
        //   activeClass: "m-icon-remote-active",
        //   label: "远程协助",
        //   active: false
        // },
        {
          name: "serviceFace",
          class: "m-icon-face",
          activeClass: "m-icon-face-active",
          label: "人脸识别"
        },
        {
          name: "serviceImage",
          class: "m-icon-img",
          activeClass: "m-icon-img-active",
          label: "图像识别"
        },
        {
          name: "serviceCar",
          class: "m-icon-car",
          activeClass: "m-icon-car-active",
          label: "车牌识别"
        },
        {
          name: "serviceSop",
          class: "m-icon-sop",
          activeClass: "m-icon-sop-active",
          label: "电子SOP"
        },
        {
          name: "Access",
          class: "m-icon-auth",
          activeClass: "m-icon-auth-active",
          label: "SDK授权"
        }
      ],
      data3: [
        // {
        //   name: "Auth",
        //   class: "m-icon-auth",
        //   activeClass: "m-icon-auth-active",
        //   label: "认证进度"
        // },
        // {
        //   name: "AuthAudit",
        //   class: "m-icon-auth",
        //   activeClass: "m-icon-auth-active",
        //   label: "认证审核"
        // },
        // {
        //   name: "Group",
        //   class: "m-icon-company",
        //   activeClass: "m-icon-company-active",
        //   label: "组织管理"
        // },
        // {
        //   name: "RemoteServer",
        //   class: "m-icon-remote",
        //   activeClass: "m-icon-remote-active",
        //   // 临时改动
        //   label: "数据可视化",
        //   active: false
        // },
        {
          name: "serviceFace",
          class: "m-icon-face",
          activeClass: "m-icon-face-active",
          label: "人脸识别"
        },
        {
          name: "serviceImage",
          class: "m-icon-img",
          activeClass: "m-icon-img-active",
          label: "图像识别"
        },
        {
          name: "serviceCar",
          class: "m-icon-car",
          activeClass: "m-icon-car-active",
          label: "车牌识别"
        },
        {
          name: "serviceSop",
          class: "m-icon-sop",
          activeClass: "m-icon-sop-active",
          label: "电子SOP"
        }
      ],
      data2: [
        // {
        //   name: "Auth",
        //   class: "m-icon-auth",
        //   activeClass: "m-icon-auth-active",
        //   label: "认证进度"
        // },
        // {
        //   name: "AuthAudit",
        //   class: "m-icon-auth",
        //   activeClass: "m-icon-auth-active",
        //   label: "认证审核"
        // },
        {
          name: "airUser",
          class: "m-icon-company",
          activeClass: "m-icon-company-active",
          label: "用户管理"
        },
        // {
        //   name: "RemoteServer",
        //   class: "m-icon-remote",
        //   activeClass: "m-icon-remote-active",
        //   label: "远程协助",
        //   active: false
        // },
        {
          name: "serviceFace",
          class: "m-icon-face",
          activeClass: "m-icon-face-active",
          label: "人脸识别"
        },
        {
          name: "serviceImage",
          class: "m-icon-img",
          activeClass: "m-icon-img-active",
          label: "图像识别"
        },
        {
          name: "serviceCar",
          class: "m-icon-car",
          activeClass: "m-icon-car-active",
          label: "车牌识别"
        },
        {
          name: "serviceSop",
          class: "m-icon-sop",
          activeClass: "m-icon-sop-active",
          label: "电子SOP"
        },
        // {
        //   name: "serviceLove",
        //   class: "m-icon-sop",
        //   activeClass: "m-icon-sop-active",
        //   label: "Love"
        // }
      ],
      data4: [
        // {
        //   name: "Auth",
        //   class: "m-icon-auth",
        //   activeClass: "m-icon-auth-active",
        //   label: "认证进度"
        // },
        // {
        //   name: "AuthAudit",
        //   class: "m-icon-auth",
        //   activeClass: "m-icon-auth-active",
        //   label: "认证审核"
        // },
        // {
        //   name: "Group",
        //   class: "m-icon-company",
        //   activeClass: "m-icon-company-active",
        //   label: "组织管理"
        // },
        // {
        //   name: "RemoteServer",
        //   class: "m-icon-remote",
        //   activeClass: "m-icon-remote-active",
        //   label: "远程协助",
        //   active: false
        // },
        {
          name: "serviceFace",
          class: "m-icon-face",
          activeClass: "m-icon-face-active",
          label: "人脸识别"
        },
        {
          name: "serviceImage",
          class: "m-icon-img",
          activeClass: "m-icon-img-active",
          label: "图像识别"
        },
        {
          name: "serviceCar",
          class: "m-icon-car",
          activeClass: "m-icon-car-active",
          label: "车牌识别"
        },
        {
          name: "serviceSop",
          class: "m-icon-sop",
          activeClass: "m-icon-sop-active",
          label: "电子SOP"
        }
      ]
    };
  },
  watch: {
    $route: function() {
      this.currentRouteName = this.$route.name;
    }
  },
  mounted() {
    this.currentRouteName = this.$route.name;
    console.log(this.currentRouteName);
    console.log(this.roleId);
  },
  methods: {
    goto(name) {
      this.currentRouteName = name;
      this.$router.push({
        name: name
      });
    }
  }
};
</script>

<style scoped lang="less">
.Aside {
  height: calc(100vh - 70px);
  .m-menu {
    padding: 30px;
    &-item {
      height: 40px;
      margin: 10px 0 0 10px;
      div {
        cursor: pointer;
      }
    }
  }

  .img {
    height: 20px;
    width: 20px;
  }
  p {
    margin-left: 10px;
    line-height: 40px;
    font-size: 16px;
  }
  .m-icon {
    &-auth {
      background-image: url("../../assets/images/air-cloud/aside/f.png");
      background-repeat: no-repeat;
      background-size: 20px 20px;
      background-position: 0 10px;
      padding-left: 25px;
      height: 40px;
      width: 120px;
      color: #ffffff;
      &-active {
        background-image: url("../../assets/images/air-cloud/aside/f1.png");
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-position: 0 10px;
        padding-left: 25px;
        height: 40px;
        width: 120px;
        color: rgba(0, 236, 255, 1);
      }
    }
    &-remote {
      background-image: url("../../assets/images/air-cloud/aside/b.png");
      background-repeat: no-repeat;
      background-size: 20px 20px;
      background-position: 0 10px;
      padding-left: 25px;
      height: 40px;
      width: 120px;
      color: #ffffff;
      &-active {
        background-image: url("../../assets/images/air-cloud/aside/b1.png");
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-position: 0 10px;
        padding-left: 25px;
        height: 40px;
        width: 120px;
        color: rgba(0, 236, 255, 1);
      }
    }
    &-face {
      background-image: url("../../assets/images/air-cloud/aside/d.png");
      background-repeat: no-repeat;
      background-size: 20px 20px;
      background-position: 0 10px;
      padding-left: 25px;
      height: 40px;
      width: 120px;
      color: #ffffff;
      &-active {
        background-image: url("../../assets/images/air-cloud/aside/d1.png");
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-position: 0 10px;
        padding-left: 25px;
        height: 40px;
        width: 120px;
        color: rgba(0, 236, 255, 1);
      }
    }
    &-company {
      background-image: url("../../assets/images/air-cloud/aside/b.png");
      background-repeat: no-repeat;
      background-size: 20px 20px;
      background-position: 0 10px;
      padding-left: 25px;
      height: 40px;
      width: 120px;
      color: #ffffff;
      &-active {
        background-image: url("../../assets/images/air-cloud/aside/b1.png");
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-position: 0 10px;
        padding-left: 25px;
        height: 40px;
        width: 120px;
        color: rgba(0, 236, 255, 1);
      }
    }
    &-sop {
      background-image: url("../../assets/images/air-cloud/aside/c.png");
      background-repeat: no-repeat;
      background-size: 20px 20px;
      background-position: 0 10px;
      padding-left: 25px;
      height: 40px;
      width: 120px;
      color: #ffffff;
      &-active {
        background-image: url("../../assets/images/air-cloud/aside/c1.png");
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-position: 0 10px;
        padding-left: 25px;
        height: 40px;
        width: 120px;
        color: rgba(0, 236, 255, 1);
      }
    }
    &-img {
      background-image: url("../../assets/images/air-cloud/aside/g.png");
      background-repeat: no-repeat;
      background-size: 20px 20px;
      background-position: 0 10px;
      padding-left: 25px;
      height: 40px;
      width: 120px;
      color: #ffffff;
      &-active {
        background-image: url("../../assets/images/air-cloud/aside/g1.png");
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-position: 0 10px;
        padding-left: 25px;
        height: 40px;
        width: 120px;
        color: rgba(0, 236, 255, 1);
      }
    }
    &-car {
      background-image: url("../../assets/images/air-cloud/aside/e.png");
      background-repeat: no-repeat;
      background-size: 20px 20px;
      background-position: 0 10px;
      padding-left: 25px;
      height: 40px;
      width: 120px;
      color: #ffffff;
      &-active {
        background-image: url("../../assets/images/air-cloud/aside/e1.png");
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-position: 0 10px;
        padding-left: 25px;
        height: 40px;
        width: 120px;
        color: rgba(0, 236, 255, 1);
      }
    }
  }
}
</style>
