export default {
  public: {
    cancel: "取消",
    clear: "清空",
    confirm: "确定",
    prompt: "提示",
    delete: "删除",
    close: "关闭",
    return: "返回",
    query: "查询",
    operation: "操作",
    details: "详情",
    modify: "修改",
    login: "登 录",
    register: "注 册",
    keep: "记住密码"
  },
  menu: {
    live: "直播管理",
    device: "设备管理",
    history: "视频回看"
  }
};
