<template>
  <div class="web-solution-desc" ref="solution" align="center">
    <div class="web-solution-desc-top" ref="solutionHeader">
      <div>
        <img
          src="../../../assets/images/websites/solution/banner2.png"
          alt=""
          style="width: 100%"
        />
      </div>
      <div class="web-solution-desc-top-menu" align="ecnter">
        <div style="width: 1240px">
          <el-row>
            <el-col
              :span="4"
              v-for="(item, index) in solutionList"
              :key="index"
            >
              <div @click="setScrollTop(item.type)">
                <img :src="item.icon2Url" alt="" />
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div ref="solutionContent" class="web-solution-desc-content">
      <div
        v-for="(item, index) in solutionData"
        :key="index"
        class="web-solution-desc-type"
      >
        <div class="title">
          <p>{{ item.title }}</p>
        </div>
        <div class="content">
          <div class="list">
            <el-row>
              <el-col
                :span="24 / item.types.length"
                v-for="(data, i) in item.types"
                :key="i"
              >
                <div
                  style="width: 15vw"
                  @mouseleave="setActiveTable('')"
                  @mouseenter="setActiveTable(data.typeKey)"
                >
                  <div>
                    <img
                      :src="
                        activeTable === data.typeKey
                          ? data.activeIcon
                          : data.typeIcon
                      "
                      class="icon"
                      alt=""
                    />
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div
            v-for="(data, i) in item.types"
            :key="i"
            @mouseenter="setActiveTable(data.typeKey)"
            v-show="activeTable === data.typeKey"
            class="desc"
          >
            <img :src="data.typeDesc" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { solutionList, solutionData } from "@/libs/dict/websites.js";

export default {
  name: "solution-desc",

  data() {
    return {
      solution: "",
      solutionHeaderHeight: "",
      solutionContentHeight: "",
      solutionList,
      solutionData,
      activeTable: "",
      scrollData: []
    };
  },
  mounted() {
    this.getContentHeight();
  },
  methods: {
    getContentHeight() {
      let _this = this;
      this.solution = this.$refs.solution;
      this.solutionHeaderHeight = this.$refs.solutionHeader.scrollHeight;
      this.solutionContentHeight = this.$refs.solutionContent.scrollHeight;
      this.scrollData = [
        { val: 0 },
        { val: this.solutionHeaderHeight },
        { val: this.solutionHeaderHeight + this.solutionContentHeight / 6 },
        {
          val: this.solutionHeaderHeight + (this.solutionContentHeight * 2) / 6
        },
        {
          val: this.solutionHeaderHeight + (this.solutionContentHeight * 3) / 6
        },
        {
          val: this.solutionHeaderHeight + (this.solutionContentHeight * 4) / 6
        },
        {
          val: this.solutionHeaderHeight + (this.solutionContentHeight * 5) / 6
        }
      ];
      localStorage.setItem("solutionScroll", JSON.stringify(this.scrollData));
      let index = this.$route.params.type;
      if (!index) {
        index = 0;
      }
      setTimeout(function() {
        _this.setScrollTop(index);
      }, 0);
    },
    setScrollTop(index) {
      document.documentElement.scrollTop = this.scrollData[index].val;
    },
    setActiveTable(table) {
      this.activeTable = table;
    }
  }
};
</script>

<style scoped></style>
