export const menuList = [
  {
    text: "首页",
    routerName: "wapHome"
  },
  { text: "产品介绍", routerName: "wapProduct" },
  { text: "解决方案", routerName: "wapSolution" },
  { text: "客户案例", routerName: "wapCase" },
  { text: "关于我们", routerName: "wapAbout" }
];
export const solutionList = [
  {
    backUrl: require("../../assets/images/wap-page/home/solution/智能制造.png"),
    iconUrl: require("../../assets/images/wap-page/home/solution/made.png")
  },
  {
    backUrl: require("../../assets/images/wap-page/home/solution/智能医疗.png"),
    iconUrl: require("../../assets/images/wap-page/home/solution/medical.png")
  },
  {
    backUrl: require("../../assets/images/wap-page/home/solution/智慧展厅.png"),
    iconUrl: require("../../assets/images/wap-page/home/solution/showroom.png")
  },
  {
    backUrl: require("../../assets/images/wap-page/home/solution/智慧安防.png"),
    iconUrl: require("../../assets/images/wap-page/home/solution/security.png")
  },
  {
    backUrl: require("../../assets/images/wap-page/home/solution/智慧文旅.png"),
    iconUrl: require("../../assets/images/wap-page/home/solution/journey.png")
  },
  {
    backUrl: require("../../assets/images/wap-page/home/solution/智慧教育.png"),
    iconUrl: require("../../assets/images/wap-page/home/solution/education.png")
  }
];
export const solutionData = [
  {
    title: "智能制造",
    active: 0,
    types: [
      {
        title: "作业指导",
        typeIcon: require("../../assets/images/wap-page/solution/list/1/1.png"),
        desc:
          "作业人员可通过AR智能眼镜实时调出相关作业指导书（图文、视频等），不只省略调阅纸本文件的时间成本，并降低纸本文件被有心人士取走之风险，更可透过AR眼镜让作业指导书跟着人员的可移动性，提高人员工作效率。",
        super: [
          "解放双手，边查看信息边操作",
          "多种形式的辅助内容，让工程更易学易懂",
          "培训和操作同步进行，降低工序风险，增加工作效率"
        ]
      },
      {
        title: "运维巡检",
        typeIcon: require("../../assets/images/wap-page/solution/list/1/2.png"),
        desc:
          "AR眼镜可推送设备维护任务，当现场设备遇故障无法单独处理时，通过AR眼镜可将现场情况以第一视角同步后台专家端，专家远程协作辅助指导，通过标注、文件传输、桌面共享等功能实时同步解决故障问题。点检、辅助维修的过程可自动保存服务器，可作为后期学习资料查阅。",
        super: [
          "设备状态信息化，根据设备状态自动形成维护计划",
          "为工程人员提供更准确的可视化实时信息",
          "提高巡检效率，增加人力资源利用率",
          "可以有效形成远程巡检创新模式，提升巡检效率与质量"
        ]
      },
      {
        title: "物资管理",
        typeIcon: require("../../assets/images/wap-page/solution/list/1/3.png"),
        desc:
          "员工佩戴AR 眼镜后可接收MES系统的拣货任务与工单，并通过对货物进行二维码、条码、标签、文数字 OCR 等进行识别，匹配对应的货物信息，实现智能视觉分拣与物资管理。同时，智能眼镜拥有 GPS 模块，通过软件开发，可以迅速匹配单一货物需要摆放的位置。",
        super: [
          "取代传统扫描枪，解放双手",
          "电子拣货工单；实现作业无纸化",
          "货物库位指引，误拣率低，拣货效率高"
        ]
      },
      {
        title: "数据可视化",
        typeIcon: require("../../assets/images/wap-page/solution/list/1/4.png"),
        desc:
          "IoT传感器反馈的设备实时数据通过与AR 眼镜结合，可使数据信息 （生产报表、产量、利用率、维保信息等）与眼前设备叠加，并且借助于虚拟触控交互技术、语音交互技术、设备姿态传感器交互技术，从真正意义上解放人的双手，提供环绕式视野，在巡检过程中为工程人员提供数据支撑。",
        super: [
          "第一视角、解放双手、数据同步",
          "数据展示对应设备，反馈更直观",
          "故障分析定位快，巡检效率高"
        ]
      }
    ]
  },
  {
    title: "智慧医疗",
    active: 0,
    types: [
      {
        title: "紧急救护",
        typeIcon: require("../../assets/images/wap-page/solution/list/2/5.png"),
        desc:
          "AR眼镜结合远程协作系统，帮助120车内紧急救护人员将现场伤患及其实时监测的生命体征值通过第一视角的音视频传输至医院，实时了解现场伤患状况，以提前部署救护方案，抢救生命",
        super: [
          "解放救护人员双手，现场采集回传与救护两不误",
          "专家可视化方式掌握病患情况，在线指导更精准"
        ]
      },
      {
        title: "手术直播与示教",
        typeIcon: require("../../assets/images/wap-page/solution/list/2/6.png"),
        desc:
          "医生头戴智能眼镜，将其第一视角手术画面实时无视角差异直播，回传至后台观看端，供医学生无差异观摩学习，可充分展现主刀医生手术技巧，并防止手术台污染。",
        super: ["永远第一视角，消灭视觉盲区，实现无差别示教"]
      },
      {
        title: "远程诊疗",
        typeIcon: require("../../assets/images/wap-page/solution/list/2/7.png"),
        desc:
          "医生头戴智能眼镜，可实时音视频传输病患情况至中心专家，实现远程会诊。",
        super: ["无需赶赴专家现场，提高就医效率", "降低就医成本"]
      }
    ]
  },
  {
    title: "智慧展厅",
    active: 0,
    types: [
      {
        title: "MR数字沙盘",
        typeIcon: require("../../assets/images/wap-page/solution/list/3/8.png"),
        desc:
          "3D建模技术结合MR智能眼镜的空间定位能力，在体验者面前构建虚拟的三维立体电子沙盘，可以游览整个区域的概况。区域中重点地区模型带有热点交互功能，体验者点击相应按键即可显示该建筑或地区的相关介绍信息。",
        super: [
          "相比传统实物沙盘，节省展厅空间",
          "具有互动性，让体验者身临其境，不易分散注意力",
          "展示内容广，又简单明了，可充分体现展示内容的特点"
        ]
      },
      {
        title: "VR智慧党建",
        typeIcon: require("../../assets/images/wap-page/solution/list/3/9.png"),
        desc:
          "基于VR虚拟技术的党建学习，透过沉浸式全景观看，以身临其境的方式学习档的诞生、发展历程。根据党建需求，选定不同主题的党建文化宣传主题内容，或提供定制化党建VR内容服务。",
        super: [
          "更立体、多元的党建学习体验方式，解决单一枯燥的学习环境和氛围",
          "身临其境式体验，直观化、生动化内容，加深学习印象，更好服务党建文化建设"
        ]
      }
    ]
  },
  {
    title: "智慧安防",
    active: 0,
    types: [
      {
        title: "人/车识别核查",
        typeIcon: require("../../assets/images/wap-page/solution/list/4/10.png"),
        desc:
          "AR 眼镜集成人脸、车牌识别技术，执法人员通过智能眼镜实时采集到的动态图像中人脸、车牌，实时与后台数据库比对并展示出画面中每个人脸、车牌的识别结果信息",
        super: [
          "无需手动输入，排查效率高",
          "非侵扰式排查，不易反感",
          "有安全排查距离，减少不安全因素",
          "不易造成人员或交通拥堵"
        ]
      },
      {
        title: "远程指挥调度",
        typeIcon: require("../../assets/images/wap-page/solution/list/4/11.png"),
        desc:
          "公共场所执法遇突发事件时，执法人员通过智能眼镜与远程协作系统，实现现场与指挥中心的异地通讯与视野共享，帮忙中心人员快速了解状况并指导或指定处理方案。",
        super: [
          "解放双手，高效执法",
          "现场状况回馈精准，利于指挥调度与计划制定"
        ]
      }
    ]
  },

  {
    title: "智慧文旅",
    active: 0,
    types: [
      {
        title: "AR互动导览",
        typeIcon: require("../../assets/images/wap-page/solution/list/5/12.png"),
        desc:
          "把 AR 利用在景区的导览中，游客通过智能眼镜在景区可以做到无导览牌导览，扫描景点识别景点名称、观看跳出的景点讲解视频，带给游客更直观、更友善的 沉浸式 AR 互动导览体验。",
        super: [
          "促进游客分享，增加景区潜在用户，助力打造网红景区",
          "增强了游客的旅游体验，能吸引更多的游客，为景区创收"
        ]
      },
      {
        title: "AR品牌推广",
        typeIcon: require("../../assets/images/wap-page/solution/list/5/13.png"),
        desc:
          "用AR增强现实，植入企业产品的品牌理念，产品功能，核心卖点，产品说明，LOGO等让用户沉浸式体验到产品的功能特点。",
        super: [
          "营造新鲜感话题感，树立口碑品牌价值形象",
          "有效地加深消费者对品牌的记忆和好感度"
        ]
      },
      {
        title: "AR景点复原",
        typeIcon: require("../../assets/images/wap-page/solution/list/5/14.png"),
        desc:
          "计算机视觉技术与 AR 技术的交叉融合，深度理解景区中的场景，基于视觉定位与 AR 实现环境识别与虚拟场景的覆盖，游客可通过智能眼镜看到身临其境到文化遗址的 AR 复原场景中。",
        super: [
          "传统景区向全新融合沉浸式体验景区转型",
          "融入AR景区概念，为游客提供更加精致的旅游路线和旅游体验服务"
        ]
      }
    ]
  },
  {
    title: "智慧教育",
    active: 0,
    types: [
      {
        title: "职教培训",
        typeIcon: require("../../assets/images/wap-page/solution/list/6/15.png"),
        desc:
          "通过VR眼镜、3D建模与模型动画技术，虚拟展示产品结构与装配训练，是对实际装备原理、操作和内部运动过程的仿真，目的在于使受训人员在沉浸环境中熟练掌握某装备整体或关键设备或某一系统的信息。",
        super: [
          "节省了传统装配训练涉及的各项费用",
          "虚拟仿真模拟，避免安全事故",
          "除常规教学、支持考核模块"
        ]
      },
      {
        title: "安全培训",
        typeIcon: require("../../assets/images/wap-page/solution/list/6/16.png"),
        desc:
          "通过VR技术，将安全知识划分很多个细分场景，设计出交互简单的体验流程与方式，通过沉浸体验学习到科普知识。可针对不同场景，比如地震、消防、气象、公共安全等领域",
        super: [
          "虚拟仿真模拟，无安全隐患",
          "减少了传统安全培训涉及的人力物力",
          "增强民众对安全知识的掌握性，提高安全意识",
          "针对各类公安安全场景，提供多元化的VR安全知识学习测评体系"
        ]
      }
    ]
  }
];

export const certificateList = [
  {
    number: 1,
    text: "高新技术企业证书",
    text1: "上海极视云信息科技有限公司在2019年被评为高新技术企业",
    text2: "",
    img: require("../../assets/images/wap-page/about/certificate/1.jpg")
  },
  {
    number: 2,
    text: "软件企业证书",
    text1: "上海极视云科技有限公司在2017年符合软件企业评估标准，评估为软件企业",
    text2: "",
    img: require("../../assets/images/wap-page/about/certificate/2.jpg")
  },
  {
    number: 3,
    text: "软件产品证书",
    text1:
      "上海极视云科技有限公司在2017在《极视云智能视觉增强人机交互软件V1.0》评估为软件产品",
    text2: "",
    img: require("../../assets/images/wap-page/about/certificate/3.jpg")
  },
  {
    number: 4,
    text: "极视云AI识别软件",
    text1: "著作软人：上海极视云信息科技有限公司",
    text2: "登记号：2019SR0347866",
    img: require("../../assets/images/wap-page/about/certificate/4.png")
  },
  {
    number: 5,
    text: "极视云远程协作系统",
    text1: "著作软人：上海极视云信息科技有限公司",
    text2: "登记号：2018SR190665",
    img: require("../../assets/images/wap-page/about/certificate/5.jpg")
  },
  {
    number: 6,
    text: "极视云智能巡检维护软件",
    text1: "著作软人：上海极视云信息科技有限公司",
    text2: "登记号：2019SR0344788",
    img: require("../../assets/images/wap-page/about/certificate/6.png")
  },
  {
    number: 7,
    text: "极视云智慧工厂移动仓储管理软件",
    text1: "著作软人：上海极视云信息科技有限公司",
    text2: "登记号：2019SR0347645",
    img: require("../../assets/images/wap-page/about/certificate/7.png")
  },
  {
    number: 8,
    text: "极视云AR眼镜3维操作软件",
    text1: "著作软人：上海极视云信息科技有限公司",
    text2: "登记号：2019SR0344917",
    img: require("../../assets/images/wap-page/about/certificate/8.png")
  },
  {
    number: 9,
    text: "极视云人脸识别考勤打卡软件",
    text1: "著作软人：上海极视云信息科技有限公司",
    text2: "登记号：2019SR348906",
    img: require("../../assets/images/wap-page/about/certificate/9.png")
  },
  {
    number: 10,
    text: "软件名称：极视云移动警务识别软件",
    text1: "著作软人：上海极视云信息科技有限公司",
    text2: "登记号：2019SR0344790",
    img: require("../../assets/images/wap-page/about/certificate/10.png")
  },
  {
    number: 11,
    text: "极视云热成像多功能通讯协作软件",
    text1: "著作软人：上海极视云信息科技有限公司",
    text2: "登记号：2019SR0344913",
    img: require("../../assets/images/wap-page/about/certificate/11.png")
  },
  {
    number: 12,
    text: "极视云智能视觉增强人机交互软件",
    text1: "著作软人：上海极视云信息科技有限公司",
    text2: "登记号：2016SR330789",
    img: require("../../assets/images/wap-page/about/certificate/12.jpg")
  },
  {
    number: 13,
    text: "虚拟现实技术工业应用联合实验室",
    text1: "",
    text2: "",
    img: require("../../assets/images/wap-page/about/certificate/13.jpg")
  },
  {
    number: 14,
    text: "智能穿戴装备联合研发实验室",
    text1: "",
    text2: "",
    img: require("../../assets/images/wap-page/about/certificate/14.jpg")
  },
  {
    number: 15,
    text: "5G中央空调应用示范基地",
    text1: "",
    text2: "",
    img: require("../../assets/images/wap-page/about/certificate/15.jpg")
  },
  {
    number: 16,
    text: "AR眼镜应用三等奖",
    text1: "",
    text2: "",
    img: require("../../assets/images/wap-page/about/certificate/16.jpg")
  },
  {
    number: 17,
    text: "“创客中国”互联网+创新创业大赛荣获“三等奖”",
    text1: "",
    text2: "",
    img: require("../../assets/images/wap-page/about/certificate/17.jpg")
  }
];

export const caseList = [
  {
    name: "5G AR智慧工厂",
    text:
      "在青岛海尔空调、冰箱5G互联工厂中，极视云科技AR/VR/MR成为其中重要组成部分，应用于培训、运维等多种场景。极视云科技已与海尔智研院达成战略合作，成立了“智能穿戴装备联合研发实验室”、“虚拟现实技术工业应用联合实验室”，在智能制造多个环节展开研究与合作，共同推动AR/VR/MR在海尔及海尔生态伙伴体系中的落地。",
    imgUrl: require("../../assets/images/wap-page/case/5G AR智慧工厂.png"),
    iconUrl: require("../../assets/images/wap-page/case/海尔.png")
  },
  {
    name: "AR智慧仓储",
    text:
      "国家电网，其车间搭建UWB室内定位系统，可实现精准的2D、1D及区域定位功能。首先与智能眼镜结合，可实时推送线路信息，帮助作业人员完成车间导航。其次，可帮助后台管理人员实现对叉车以及车间人员的实时定位，记录其工作时的运动轨迹，完成工作效率与进度的监察，如遇突发事件，可进行科学合理的调度安排。",
    imgUrl: require("../../assets/images/wap-page/case/AR智慧仓储.png"),
    iconUrl: require("../../assets/images/wap-page/case/国家电网.png")
  },
  {
    name: "AR智能运维平台",
    text:
      "杭州中车以软件平台为依托，以智能化检测装备、AR穿戴设备为工具，整体构建“ 线上资源整合+线下服务投送 ”的轨道交通装备运维服务体系。通过对知识图谱、机器视觉、智能控制和自动规划等前沿技术的运用，实现平台工具各项功能与资源调配、数据分析、异常识别、故障诊断和维修管护等作业过程的紧密贴合，从而实现装备可靠性和行业人均效能的整体跃升。",
    imgUrl: require("../../assets/images/wap-page/case/AR智能运维平台.png"),
    iconUrl: require("../../assets/images/wap-page/case/中车.png")
  },
  {
    name: "5G AR医疗全景直播",
    text:
      "高平市人民医院借助VR探视系统，可以将前端新生儿房内全景摄像头拍摄的全景音视频单向传输至后端医生或家属，其可以通过电脑或是佩戴VR眼镜完成远程探视。远程探视过程中，医生或家属可以查看婴儿身处环境的超清画质的视频，且可以通过探视者所需查看病房内不同视角的内容。此系统可帮助医生、家属随时随地了解婴儿的情况，实现身临其境式探视，更能杜绝亲临现场探视将自身细菌传播至婴儿的隐患。",
    imgUrl: require("../../assets/images/wap-page/case/5G AR医疗全景直播.png"),
    iconUrl: require("../../assets/images/wap-page/case/中移.png")
  },
  {
    name: "AR智能生产线",
    text:
      "菲尼克斯电气和极视云科技合作AR智能生产线系统，通过引入人脸识别、图片像素化等AR技术，提高企业个性化服务能力。系统中AR、智能物流、智能包装、定制配送等智能体系相互配合，保障产线的智能化管控。",
    imgUrl: require("../../assets/images/wap-page/case/AR智能生产线.png"),
    iconUrl: require("../../assets/images/wap-page/case/菲尼克斯.png")
  },
  {
    name: "MR智慧展厅",
    text:
      "为了打破传统展厅介绍模式，向丰富生动、交互性强的智慧展厅转型，杭州正泰将MR眼镜融入展厅介绍之中，结合SLAM、3D建模、动画渲染等技术，实现了展厅在内容、模式、推广上的创新，是互联网时代新的一种传播推广方式。相比传统展厅，智慧展厅在展示时间、覆盖面、便捷性、传播速度等方面有着较大优势。",
    imgUrl: require("../../assets/images/wap-page/case/MR智慧展厅.png"),
    iconUrl: require("../../assets/images/wap-page/case/chint.png")
  },
  {
    name: "AR智能执法",
    text:
      "2018年两会期间，北京市局结合极视云AR智能眼镜，人脸、车牌识别技术，实现了在毫秒级的时间周期内快速精准完成人脸、车牌的识别比对。协助公安实现智能高效的人员核查、指挥调度、布控协作、员工管理等，打造一条多方位、多维度、立体化的深层次感知预警防线，建设智慧机场安防新业态。",
    imgUrl: require("../../assets/images/wap-page/case/AR智能执法.png"),
    iconUrl: ""
  }
];
