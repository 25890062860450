<template>
  <div class="air-main">
    <el-container>
      <el-header class="air-main-header" height="70px">
        <div>
          <air-header> </air-header>
        </div>
      </el-header>
      <el-container class="air-main-content">
        <el-aside class="air-main-aside" width="230px">
          <air-aside></air-aside>
        </el-aside>
        <el-main class="air-main-main" align="left">
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import AirHeader from "@/components/air-cloud/AirHeader";
import AirAside from "@/components/air-cloud/AirAside";
export default {
  name: "AirMain",
  components: { AirAside, AirHeader }
};
</script>

<style scoped></style>
