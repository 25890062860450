import store from "@/store";
import { getToken } from "@/libs/util.js";

// 审核状态相关
export const auditStatus = [
  {
    key: 1,
    value: "审核中"
  },
  {
    key: 2,
    value: "审核通过"
  },
  {
    key: 3,
    value: "审核未通过"
  }
];
// 图像类型相关
export const annexTypes = [
  {
    value: "0",
    label: "文本/Text"
  },
  {
    value: "1",
    label: "视频/Video"
  },
  {
    value: "2",
    label: "音频/Audio"
  },
  {
    value: "3",
    label: "模型/Model"
  },
  {
    value: "4",
    label: "全景视频/Panoramic Video"
  }
];
export const productImg = [
  require("../../assets/images/air-cloud/server/yuanchengxiezuo.png"),
  require("../../assets/images/air-cloud/server/tuxiangshibe.png"),
  require("../../assets/images/air-cloud/server/chepaishibie.png"),
  require("../../assets/images/air-cloud/server/renlianshibie.png"),
  require("../../assets/images/air-cloud/server/dianziSOP.png"),
  require("../../assets/images/air-cloud/server/tvrtc.png"),
  require("../../assets/images/air-cloud/server/live.png")
];
export const serviceBuyDesc = [
  require("../../assets/images/air-cloud/home/remote_setting.png"),
  require("../../assets/images/air-cloud/home/img_setting.png"),
  require("../../assets/images/air-cloud/home/car_setting.png"),
  require("../../assets/images/air-cloud/home/face_setting.png"),
  require("../../assets/images/air-cloud/home/sop_setting.png")
];
export const productList = [
  {
    value: "1",
    label: "远程协助"
  },
  {
    value: "2",
    label: "图像识别"
  },
  {
    value: "3",
    label: "车牌识别"
  },
  {
    value: "4",
    label: "人脸识别"
  },
  {
    value: "5",
    label: "ESop"
  }
];

// 上传相关
export const uploadFile =
  process.env.NODE_ENV === "production"
    ? "/aircloud/api/system/upload/file"
    : "/system/upload/file";
export const uploadSop =
  process.env.NODE_ENV === "production"
    ? "/aircloud/api/system/upload/sop"
    : "/system/upload/sop";
export const uploadCertificate =
  process.env.NODE_ENV === "production"
    ? "/aircloud/api/system/upload/file/certificate"
    : "/system/upload/file/certificate";

export const headers = {
  token: store.state.user.token ? store.state.user.token : getToken()
};

// 校验相关
export const rules = {
  //SOP
  sopNum: [
    { required: true, message: "SOP编号不能为空", trigger: "blur" },
    {
      min: 1,
      message: "SOP编号不能为空",
      trigger: "blur"
    }
  ],
  sopDesc: [{ required: true, message: "SOP标题不能为空", trigger: "blur" }]
};
export const carRules = {
  // 車牌
  carNum: [{ required: true, message: "车牌号码不能为空", trigger: "blur" }],
  color: [
    { required: true, message: "请设置识别后展示的颜色", trigger: "blur" }
  ]
};
export const faceRules = {
  //SOP
  token: [{ required: true, message: "请上传人脸头像素材", trigger: "blur" }],
  name: [{ required: true, message: "人物姓名不能为空", trigger: "blur" }],
  color: [
    { required: true, message: "请设置识别后展示的颜色", trigger: "blur" }
  ]
};
export const imgRules1 = {
  //SOP
  encryptionFileName: [
    { required: true, message: "请上传识别素材", trigger: "blur" }
  ],
  mediaName: [{ required: true, message: "素材名称不能为空", trigger: "blur" }],
  mediaContent: [
    { required: true, message: "展示内容不能为空", trigger: "blur" }
  ]
};
export const imgRules2 = {
  //SOP
  encryptionFileName: [
    { required: true, message: "请上传识别素材", trigger: "blur" }
  ],
  mediaName: [{ required: true, message: "素材名称不能为空", trigger: "blur" }],
  extEncryptionFileName: [
    { required: true, message: "展示内容不能为空", trigger: "blur" }
  ]
};
export const deptRules = {
  //企业下部门
  deptName: [{ required: true, message: "部门名称不能为空", trigger: "blur" }],
  master: [{ required: true, message: "联系人不能为空", trigger: "blur" }],
  phone: [{ required: true, message: "联系电话不能为空", trigger: "blur" }]
};
export const userRules = {
  // 企业下用户

  realName: [{ required: true, message: "用户姓名不能为空", trigger: "blur" }],
  mobile: [{ required: true, message: "手机号码不能为空", trigger: "blur" }],
  password: [{ required: true, message: "用户密码不能为空", trigger: "blur" }],
  deptId: [{ required: true, message: "所属部门不能为空", trigger: "blur" }]
};
export const orderRules = {
  // 企业下用户
  companyId: [{ required: true, message: "企业不能为空", trigger: "blur" }],
  pid: [{ required: true, message: "服务类别不能为空", trigger: "blur" }],
  priceId: [{ required: true, message: "服务类别不能为空", trigger: "blur" }]
};
export const accessRules = {
  // 企业下用户
  companyId: [{ required: true, message: "企业不能为空", trigger: "blur" }],
  pid: [{ required: true, message: "服务类别不能为空", trigger: "blur" }],
  priceId: [{ required: true, message: "服务类别不能为空", trigger: "blur" }]
};
export const forgetRules = {
  // 企业下用户
  mobile: [{ required: true, message: "手机号码不能为空", trigger: "blur" }],
  code: [{ required: true, message: "验证码不能为空", trigger: "blur" }],
  newPassword: [{ required: true, message: "密码不能为空", trigger: "blur" }]
};
export const activeRules = {
  // 企业下用户
  mobile: [{ required: true, message: "手机号码不能为空", trigger: "blur" }],
  code: [{ required: true, message: "验证码不能为空", trigger: "blur" }]
};
