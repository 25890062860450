<template>
  <div class="wap-page">
    <div align="center" class="solution">
      <the-header></the-header>
      <div>
        <img
          src="../../../assets/images/wap-page/solution/banner.png"
          style="width: 100%;"
          alt=""
        />
      </div>
      <div>
        <div style="width: 92%">
          <div v-for="(item, index) in solutionData" :key="index">
            <div class="title1">
              <p>{{ item.title }}</p>
            </div>
            <div>
              <el-row>
                <el-col
                  :span="24 / item.types.length"
                  v-for="(types, num) in item.types"
                  :key="num"
                >
                  <div @click="item.active = num" class="type-img">
                    <div :class="item.active === num ? 'active' : ''">
                      <img :src="types.typeIcon" alt="" class="img" />
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div>
              <div class="title2">
                <p>{{ item.types[item.active].title }}</p>
              </div>
              <div align="left" class="text">
                <div style="padding:1rem;border-bottom: 2px solid #D9EBFD">
                  <div>
                    <p>[简介]</p>
                  </div>
                  <div>
                    <p>
                      {{ item.types[item.active].desc }}
                    </p>
                  </div>
                </div>

                <div style="padding: 1rem">
                  <div>
                    <p>[优势]</p>
                  </div>
                  <div>
                    <p v-for="(i, j) in item.types[item.active].super" :key="j">
                      <span class="icon"></span>
                      {{ i }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <the-footer></the-footer>
    </div>
  </div>
</template>

<script>
import TheHeader from "@/components/wap-page/TheHeader";
import { solutionData } from "@/libs/dict/wap-page.js";
import TheFooter from "@/components/wap-page/TheFooter";

export default {
  name: "Solution",
  components: { TheFooter, TheHeader },
  data() {
    return {
      solutionData,
      active1: 1
    };
  },
  mounted() {},
  methods: {
    setActive() {}
  }
};
</script>

<style scoped></style>
