<template>
  <div class="esop">
    <div class="esop-main">
      <el-container>
        <el-aside>
          <editor-aside></editor-aside>
        </el-aside>
        <el-main>
          <div style="height: calc(100vh - 40px)">
            <router-view />
          </div>
        </el-main>
      </el-container>
    </div>
  </div>
</template>

<script>
import EditorAside from "./EditorAside";
export default {
  name: "EditorMain",
  components: { EditorAside }
};
</script>

<style scoped></style>
