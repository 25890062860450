<template>
  <div class="esop-aside">
    <div class="esop-aside-title">
      <el-row>
        <el-col :span="8" align="right">
          <div><img src="../../assets/icon.png" alt="" class="logo" /></div>
        </el-col>
        <el-col :span="8">
          <div class="name"><p>Topvision</p></div>
        </el-col>
      </el-row>
    </div>
    <div class="esop-aside-content">
      <div
        @click="openPage('editorProject')"
        :class="
          currentRouterName === 'editorProject'
            ? 'menu-button-active'
            : 'menu-button'
        "
      >
        <el-row>
          <el-col :span="8" align="right">
            <div class="icon">
              <i class="font_family m-icon-project"></i>
            </div>
          </el-col>
          <el-col :span="16">
            <div class="button"><p>项目管理</p></div>
          </el-col>
        </el-row>
      </div>
      <div
        @click="openPage('editorResource')"
        :class="
          currentRouterName === 'editorResource'
            ? 'menu-button-active'
            : 'menu-button'
        "
      >
        <el-row>
          <el-col :span="8" align="right">
            <div class="icon">
              <i class="font_family m-icon-rouserce"></i>
            </div>
          </el-col>
          <el-col :span="16">
            <div class="button"><p>资源管理</p></div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="esop-aside-footer">
      <div>
        <div style="height: 40px">
          <el-row>
            <el-col :span="7">
              <div class="icon">
                <div align="right">
                  <i
                    class="font_family m-icon-user"
                    style="color: @fontColor"
                  ></i>
                </div>
              </div>
            </el-col>
            <el-col :span="8" align="left">
              <div class="name">
                <p>{{ userInfo.realName }}</p>
              </div>
            </el-col>
            <el-col :span="9">
              <div class="button">
                <button @click="logOutPge()" class="btn-logout">
                  退出登录
                </button>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "EditorAside",
  data() {
    return {
      userInfo: this.$store.state.user.userInfo,
      currentRouterName: "",
    }
  },
  mounted() {
    this.currentRouterName = this.$route.name;
  },
  watch: {
    "$route.name": function (newVal) {
      this.currentRouterName = newVal;
    }
  },
  methods: {
    ...mapActions(["handleLogOut"]),
    openPage(routerName) {
      this.$router.push({
        name: routerName
      });
    },
    logOutPge() {
      this.handleLogOut().then(res => {
        if (res.code === 200) {
          this.$router.push({
            name: "airLogin"
          });
        }
      });
    }
  }
}
</script>
