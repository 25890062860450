<template>
  <div class="footer">
    <div class="title3">
      <p>商务合作</p>
    </div>
    <div class="title4">
      <p>电话：17701602348</p>
    </div>
    <div class="title4">
      <p>周一至周五 9:00-18:00</p>
    </div>
    <div style="padding-bottom: 1rem">
      <div style="display: inline-block">
        上海极视云信息科技有限公司 版权所有
      </div>
      <div>
        <div style="display: inline-block" @click="openICP">
          沪ICP备2021020579号-1 |
        </div>
        <div
          style="display: inline-block;  cursor: pointer;padding-left: 5px"
          @click="openGov"
        >
          沪公网安备 31011202014009号
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheFooter",
  data() {
    return {};
  },
  mounted() {},
  methods: {
    openICP() {
      window.open("https://beian.miit.gov.cn", "_black");
    },
    openGov() {
      window.open(
        "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202014009",
        "_black"
      );
    }
  }
};
</script>

<style scoped></style>
