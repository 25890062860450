<template>
  <div>
    <el-form :model="form" ref="form" :rules="rules" label-width="80px">
      <el-row>
        <el-col :span="11">
          <el-form-item prop="name" label="设备名称">
            <el-input
              v-model="form.name"
              placeholder="请输入设备名称"
              maxlength="10"
            ></el-input> </el-form-item
        ></el-col>
        <el-col :span="11">
          <el-form-item prop="description" label="设备描述">
            <el-input
              v-model="form.description"
              placeholder="请输入设备描述"
              maxlength="20"
            ></el-input> </el-form-item
        ></el-col>
      </el-row>
      <el-row>
        <el-col :span="11">
          <el-form-item prop="serialNumber" label="设备编号">
            <el-input
              disabled
              v-model="form.serialNumber"
              placeholder="请输入设备编号"
              maxlength="20"
            ></el-input> </el-form-item
        ></el-col>
        <el-col :span="11">
          <el-form-item prop="type" label="设备类型">
            <el-select v-model="form.type" style="width: 200px">
              <el-option :value="0" label="全景设备"></el-option>
              <el-option :value="1" label="平面设备"></el-option>
              <el-option :value="2" label="监控摄像头"></el-option>
            </el-select> </el-form-item
        ></el-col>
      </el-row>
    </el-form>
    <el-button @click="submit">保 存</el-button>
  </div>
</template>

<script>
import { modDevice } from "@/api/live/device";

export default {
  name: "ModifyDevice",
  props: {
    data: Object
  },
  mounted() {
    this.form = JSON.parse(JSON.stringify(this.data));
  },
  data() {
    return {
      form: {
        id: undefined,
        name: "",
        description: "",
        secretKey: "",
        serialNumber: "",
        status: 1,
        type: "",
        forwardUrl: "",
        record: 0,
        startTime: "08:00",
        endTime: "20:00"
      },
      rules: {
        name: [
          { required: true, message: "设备名称不能为空", trigger: "blur" }
        ],
        serialNumber: [
          { required: true, message: "设备编号不能为空", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const tempData = Object.assign({}, this.form);
          modDevice(tempData).then(res => {
            if (res.code === 200) {
              this.$emit("close", "");
            } else {
              this.$message.warning(res.msg);
            }
          });
        }
      });
    }
  }
};
</script>

<style scoped></style>
