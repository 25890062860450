<template>
  <div align="center">
    <div style="padding-top: 200px">
      <el-row>
        <el-col :span="10" align="right">
          <img src="../../assets/images/404/404.png" alt="" />
        </el-col>
        <el-col :span="12" align="left">
          <div class="sorry">
            <p>
              Sorry
            </p>
          </div>
          <div class="text">
            <p>
              您的页面走到太空啦，正在给您找回...
            </p>
          </div>
          <div align="right" style="width: 600px">
            <button class="back" type="button" @click="goHome()">
              返回首页
            </button>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "error_404",
  methods: {
    goHome() {
      this.$router.push({ name: "home" });
    }
  }
};
</script>
<style scoped>
.sorry {
  width: 158px;
  height: 49px;
  font-size: 50px;
  font-family: FYZYT-;
  font-weight: bold;
  color: #1ba4ff;
  line-height: 58px;
}
.text {
  width: 606px;
  height: 101px;
  font-size: 42px;
  font-family: FYZYT-;
  font-weight: bold;
  color: #2c2c2c;
  line-height: 58px;
}
.back {
  border: none;
  width: 213px;
  height: 59px;
  background: #37afff;
  border-radius: 3px;
  font-size: 25px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  line-height: 58px;
}
.back:hover {
  background: #83cdff;
}
</style>
