import Cookies from "js-cookie";
import config from "@/config";

const { cookieExpires } = config;
export const TOKEN_KEY = "token";

export const setToken = token => {
  Cookies.set(TOKEN_KEY, token, { expires: cookieExpires || 1 });
};

export const getToken = () => {
  const token = Cookies.get(TOKEN_KEY);
  if (token || token !== "false") return token;
  else return false;
};

export const removeToken = () => {
  Cookies.remove(TOKEN_KEY);
};

export const setCookies = (key, value) => {
  Cookies.set(key, value, { expires: cookieExpires || 1 });
};
export const getCookies = key => {
  const value = Cookies.get(key);
  if (value) {
    if (value === "false") {
      return false;
    } else if (value === "true") {
      return true;
    } else {
      return value;
    }
  } else {
    return false;
  }
};
export const removeCookie = key => {
  Cookies.remove(key);
};
/**
 * 生成重复的字符串
 * 用法： repeat(str, num)
 * @param str
 * @param num
 * @returns {*}
 */
export const repeat = (str, num) => {
  return new Array(num + 1).join(str);
};
/**
 * md5加密方法  不要修改
 * 用法：  this.$getMD5(str)
 * @param str
 * @returns {*}
 */
import crypto from "crypto";
export const getMD5 = function(str) {
  const md5 = crypto.createHash("md5");
  md5.update(str);
  return md5.digest("hex");
};

export const localSave = (key, value) => {
  localStorage.setItem(key, value);
};

export const localRead = key => {
  return localStorage.getItem(key) ? localStorage.getItem(key) : "";
};
export const localRemove = key => {
  localStorage.removeItem(key);
};
/**
 * 返回name所在路由表的那个项目中
 * 用法： getToRouterProject(routers, name)
 * @returns {*}
 * @param routers
 * @param name
 */

export const getToRouterProject = (routers, name) => {
  let result = false;
  for (let key in routers) {
    for (let key2 in routers[key]) {
      if (name === routers[key][key2]) {
        result = key;
      }
    }
  }
  return result;
};
