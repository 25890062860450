<template>
  <div class="live-login" align="center">
    <div class="live-login-form" align="center">
      <login-form></login-form>
    </div>
  </div>
</template>

<script>
import LoginForm from "@/views/vr-live/login/components/LoginForm";
export default {
  name: "login",
  components: { LoginForm }
};
</script>

<style scoped></style>
