<template>
  <div class="air-dialog" align="center">
    <div class="air-dialog-close" align="right">
      <button type="button" @click="close">×</button>
    </div>
    <div class="air-dialog-title">
      <p>扫码登录</p>
    </div>
    <div class="air-dialog-content" :id="'user' + qrCode">
      <VueQr :text="qrCode" :size="200" :margin="3"></VueQr>
      <!--<VueQr-->
      <!--:text="qrCode"-->
      <!--:size="200"-->
      <!--:margin="3"-->
      <!--&gt;</VueQr>-->
      <div style="padding-top: 30px">
        <p>可以使用二维码扫描或账号密码进行登录，也可进入个人信息进行查看。</p>
      </div>
    </div>
    <div class="air-dialog-footer">
      <button type="button" @click="downloadImg(qrCode)" class="air-btn-save">
        保存二维码
      </button>
    </div>
  </div>
</template>

<script>
import VueQr from "vue-qr";
export default {
  name: "MyCode",
  components: { VueQr },
  data() {
    return {
      qrCode: this.$store.state.user.userInfo.qrcode
    };
  },
  methods: {
    downloadImg(name) {
      var qrCode = document.getElementById("user" + name).firstChild;
      var url = qrCode.src;
      var a = document.createElement("a");
      var event = new MouseEvent("click");
      a.download = "我的二维码.jpg";
      a.href = url;
      a.dispatchEvent(event);
      console.log(qrCode);
    },
    close() {
      this.$emit("close", {});
    }
  }
};
</script>

<style scoped></style>
