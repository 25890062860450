<template>
  <div class="web-page">
    <div>
      <div>
        <img
          src="../../../assets/images/websites/case/客户案例-头.png"
          alt=""
          style="width: 100%"
        />
      </div>
    </div>
    <div style="padding-top: 100px">
      <div class="case-item" v-for="(item, index) in caseList" :key="index">
        <div class="case-item-content">
          <div class="case-item-content-title">
            {{ item.name }}
          </div>
          <div class="case-item-content-icon">
            <img :src="item.iconUrl" alt="" />
          </div>
          <div class="case-item-content-text">
            {{ item.text }}
          </div>
        </div>
        <div class="case-item-image">
          <img :src="item.imgUrl" alt="" />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { caseList } from "@//libs/dict/websites";

export default {
  name: "case",
  data() {
    return {
      caseList
    };
  }
};
</script>

<style scoped></style>
