import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./locale";

// 引入全局less
import "@/style/theme/theme.less";
import "@/style/common/index.less";
import "@/style/websites/index.less";
import "@/assets/icon/iconfont.css";
import "@/style/live/index.less";
import "@/style/air-cloud/index.less";
import "@/style/ar-editor/index.less";
import "@/style/wap-page/index.less";

// 引入 element UI
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
