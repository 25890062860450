<template>
  <div class="header">
    <div align="right" class="menu-open">
      <button @click="openMenu" class="menu-open-btn" type="button">
        <span class="sr-only">1</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
    </div>
    <div v-show="showMenu" class="menu">
      <div align="right" class="menu-close">
        <button @click="openMenu" class="menu-close-btn" type="">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div align="left">
        <div
          class="menu-item"
          v-for="(item, index) in menuList"
          :key="index"
          @click="goto(item)"
        >
          <p>{{ item.text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { menuList } from "../../libs/dict/wap-page.js";

export default {
  name: "TheHeader",
  data() {
    return {
      menuList,
      showMenu: false
    };
  },
  mounted() {},
  methods: {
    openMenu() {
      this.showMenu = !this.showMenu;
    },
    goto(item) {
      this.$router.push({
        name: item.routerName
      });
    }
  }
};
</script>

<style scoped></style>
