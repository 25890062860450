import axios from "@/libs/request/api.request";
export const queryLives = data => {
  return axios.request({
    url: "/rs/lives/list",
    params: data,
    method: "get"
  });
};

export const kickOutDevice = data => {
  return axios.request({
    url: "/rs/lives/clients/" + data.clientId + "/devices/" + data.deviceId,
    method: "delete"
  });
};
