<template>
  <div class="wap-page">
    <div class="product" align="center">
      <the-header></the-header>
      <div>
        <img
          src="../../../assets/images/wap-page/product/banner.png"
          style="width: 100%"
          alt=""
        />
      </div>
      <div>
        <div style="width: 92%" align="left" class="product-software">
          <div class="product-software-item">
            <div class="title2">
              <p>视频会议系统</p>
            </div>
            <div class="text">
              <p>
                在作业过程中，使用极视云AR远程协作系统，可支持AR眼镜现场端与远程专家的音视频双向实时沟通。远程专家可以实时看见AR眼镜现场第一视角的设备状况、操作内容，并在画面中标注指导信息，传输相关文件，专家端电脑屏幕共享，实时同步到现场人员佩戴的AR眼镜上，高效解决问题。
              </p>
              <p>
                提供更自然的远程通信体验，为工业制造、信息技术、医疗、保险、石化、电力、铁路、教育科研等行业，供针对远程维修、售后服务、设施巡检、医护诊疗、示范教学、监督检查、审核查验等相关业务场景设计的专业通讯系统。更可根据用户需求提供业务系统集成以及定制化开发服务。
              </p>
            </div>
            <div class="img">
              <img
                src="../../../assets/images/wap-page/product/software1.png"
                style="width: 100%"
                alt=""
              />
            </div>
          </div>
          <div class="product-software-item">
            <div class="title2">
              <p>人机交互软件</p>
            </div>
            <div class="text">
              <p>
                根据不同的智能眼镜终端及其系统，可提供不同的人机交互界面与方式的能力。
              </p>

              <p>
                <span class="icon"></span>
                人机交互界面：可根据终端的显示屏分辨率以及客户需求进行界面的定制化。
              </p>
              <p>
                <span class="icon"></span>
                人机交互方式：语音控制、头部控制、触控、眼球追踪、手势识别。
              </p>
            </div>
            <div class="img">
              <img
                src="../../../assets/images/wap-page/product/software2.png"
                style="width: 100%"
                alt=""
              />
            </div>
          </div>
          <div class="product-software-item">
            <div class="title2">
              <p>上层应用软件</p>
            </div>
            <div class="text">
              <p>
                根据客户场景所需，结合AR智能眼镜进行软件应用的四大定制化开发。
              </p>
              <p>
                <span class="icon"></span>
                用户界面定制化
              </p>
              <p>
                <span class="icon"></span>
                软件功能定制化
              </p>
              <p>
                <span class="icon"></span>
                业务流程定制化
              </p>
              <p>
                <span class="icon"></span>
                交互方式定制化
              </p>
            </div>
            <div class="img">
              <img
                src="../../../assets/images/wap-page/product/software3.png"
                style="width: 100%"
                alt=""
              />
            </div>
          </div>
          <div class="product-software-item">
            <div class="title2">
              <p>云端管理平台</p>
            </div>
            <div class="text">
              <p>
                一款综合全面的企业移动化管理平台，为用户提供了基础的账号、设备、资料的信息化管理，同时又拥有实时信息存储、分析及统计等功能。具备通过场景学习、数据分析，形成知识沉淀，实现智慧共享。
              </p>
              <p>
                <span class="icon"></span
                >全面：可对接第三方后台系统，数据全链路贯通
              </p>
              <p>
                <span class="icon"></span>高效：高效支撑企业百万级并发处理需求
              </p>
              <p>
                <span class="icon"></span>效率：资料数据新增保存，效率提升20%
              </p>
              <p><span class="icon"></span>精细：多维度统计，可视化报表</p>
              <p><span class="icon"></span>自动：可与多种智能设备进行集成</p>
              <p>
                <span class="icon"></span>灵活：公/私有云部署，支持多组织管理
              </p>
            </div>
            <div class="img">
              <img
                src="../../../assets/images/wap-page/product/software4.png"
                style="width: 100%"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="product-algorithm">
          <div class="title1">
            <p>
              AR-AI算法识别系统
            </p>
          </div>
          <div style="width: 92%">
            <div class="product-algorithm-item">
              <div style="background-color: #F0F0F0">
                <el-row>
                  <el-col :span="12">
                    <div>
                      <img
                        src="../../../assets/images/wap-page/product/recog1.png"
                        alt=""
                        style="width: 100%"
                      />
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div style="width: 92%">
                      <div class="title2">
                        <p>图像识别</p>
                      </div>
                      <div align="left">
                        <p>公有云全线免费试用1个月</p>
                        <p>同步返回系统配置信息</p>
                        <p>抗干扰能力强</p>
                        <p>支持定制化识图，可满足非通用场景下的业务需求</p>
                        <p>
                          组合接口API调用，可自由选择需要的垂类识别服务，一次传图，多垂类同时识别
                        </p>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="product-algorithm-item">
              <div style="background-color: #F0F0F0">
                <el-row>
                  <el-col :span="12">
                    <div>
                      <img
                        src="../../../assets/images/wap-page/product/recog2.png"
                        alt=""
                        style="width: 100%"
                      />
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div style="width: 92%">
                      <div class="title2">
                        <p>人脸识别</p>
                      </div>
                      <div align="left">
                        <p>公有云全线免费试用1个月</p>
                        <p>支持活体检测</p>
                        <p>端云能力齐全，适配多种应用场景</p>
                        <p>
                          服务提供的应用程序接口功能简单易用；配套文档描述详细，方便客户使用与集成
                        </p>
                        <p>
                          稳定服务保障
                        </p>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="product-algorithm-item">
              <div style="background-color: #F0F0F0">
                <el-row>
                  <el-col :span="12">
                    <div>
                      <img
                        src="../../../assets/images/wap-page/product/recog3.png"
                        alt=""
                        style="width: 100%"
                      />
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div style="width: 92%">
                      <div class="title2">
                        <p>车牌识别</p>
                      </div>
                      <div align="left">
                        <p>公有云全线免费试用1个月</p>
                        <p>离线识别SDK</p>
                        <p>提供一体机和软件部署包两种私有化方案</p>
                        <p>
                          识别结果中可返回后台系统配置的字段内容，让业务功能更完善
                        </p>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>
      </div>
      <the-footer></the-footer>
    </div>
  </div>
</template>

<script>
import TheFooter from "@/components/wap-page/TheFooter";
import TheHeader from "@/components/wap-page/TheHeader";
export default {
  name: "Product",
  components: { TheHeader, TheFooter }
};
</script>

<style scoped></style>
