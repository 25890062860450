import Vue from "vue";
import VueI18n from "vue-i18n";
import elementEnLocale from "element-ui/lib/locale/lang/en";
import elementZhLocale from "element-ui/lib/locale/lang/zh-CN";
import enLocale from "./lang/en";
import zhLocale from "./lang/zh";
Vue.use(VueI18n);
const messages = {
  en: {
    ...elementEnLocale,
    ...enLocale // 或者用 Object.assign({ message: 'hello' }, enLocale)
  },
  zh: {
    ...elementZhLocale,
    ...zhLocale // 或者用 Object.assign({ message: '你好' }, zhLocale)
  }
};
// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: "zh", // set locale
  messages // set locale messages
});

export default i18n;
