import axios from "@/libs/request/api.request";
export const getUserList = data => {
  return axios.request({
    url: "/system/user/queryAll",
    data,
    method: "post"
  });
};
export const getUserListByPage = data => {
  return axios.request({
    url:
      "/system/user/list?pageIndex=" +
      data.pageIndex +
      "&pageSize=" +
      data.pageSize,
    data,
    method: "post"
  });
};
export const saveUser = data => {
  return axios.request({
    url: "/system/user/save",
    data,
    method: "post"
  });
};

export const updateUser = data => {
  return axios.request({
    url: "/system/user/update",
    data,
    method: "post"
  });
};

export const deleteUser = data => {
  return axios.request({
    url: "/system/user/remove",
    data,
    method: "post"
  });
};
