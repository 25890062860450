<template>
  <div class="web-page">
    <div ref="top" style="height: 41.58vw;">
      <div>
        <img
          src="../../../assets/images/websites/product/产品介绍-头.png"
          alt=""
          style="width: 100%"
        />
      </div>
    </div>
    <div ref="type1">
      <div style="width: 1240px">
        <div class="title">
          视频会议系统
        </div>
        <div class="content">
          <div>
            <div class="inline-block" style="width: 685px;">
              <img
                src="../../../assets/images/websites/product/视频会议系统.png"
                alt=""
              />
            </div>
            <div class="inline-block" style="width: 497px;margin-left: 57px">
              <div
                style="font-size: 21px;text-align: left;line-height: 46px;letter-spacing:1px;padding-top: 15px"
              >
                <div style="text-indent:2em;">
                  在作业过程中，使用我司AR远程协作系统，可支持AR眼镜现场端与远程专家的音视频双向实时沟通。远程专家可以实时看见AR眼镜现场第一视角的设备状况、操作内容，并在画面中标注指导信息，传输相关文件，专家端电脑屏幕共享，实时同步到现场人员佩戴的AR眼镜上，高效解决问题。
                </div>
                <div style="text-indent:2em;">
                  提供更自然的远程通信体验，为工业制造、信息技术、医疗、保险、石化、电力、铁路、教育科研等行业，供针对远程维修、售后服务、设施巡检、医护诊疗、示范教学、监督检查、审核查验等相关业务场景设计的专业通讯系统。更可根据用户需求提供业务系统集成以及定制化开发服务。
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="text-align: center">
          <div
            class="inline-block"
            v-for="(item, index) in remoteList"
            :key="index"
            :style="{
              width: '130px',
              marginTop: '40PX',
              marginBottom: '40px',
              marginRight: (index + 1) % 4 ? '240px' : '0',
              fontSize: '17px'
            }"
          >
            <div>
              <img :src="item.iconUrl" alt="" />
            </div>
            <div style="padding-top: 20px">
              {{ item.text1 }}
            </div>
            <div style="padding-top: 12px">
              {{ item.text2 }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div ref="type2" class="web-product-software">
      <div style="width: 1240px;color: #FFFFFF;text-align: left">
        <div>
          <div class="inline-block" style="width: 547px">
            <div class="title" style="padding: 23px 0 50px 0">
              人机交互软件
            </div>
            <div class="content" style="font-size: 21px;letter-spacing: 1px;">
              <div style="text-indent:2em;">
                根据不同的智能眼镜终端及其系统，可提供不同的人机交互界面与方式的能力。
              </div>
              <div>
                ● 人机交互界面：可根据终端的显示屏分辨率以及客户
              </div>
              <div style="text-indent:20px">
                需求进行界面的定制化
              </div>
              <div>
                ● 人机交互方式：语音控制、头部控制、触控、眼球追
              </div>
              <div style="text-indent:20px">
                踪、手势识别
              </div>
            </div>
          </div>
          <div class="inline-block" style="width: 612px;margin-left: 65px">
            <img
              src="../../../assets/images/websites/product/人机交互软件.png"
              alt=""
            />
          </div>
        </div>
        <div style="margin-top: 40px">
          <div class="inline-block" style="width: 607px;">
            <img
              src="../../../assets/images/websites/product/上层应用软件.png"
              alt=""
            />
          </div>
          <div class="inline-block" style="width: 575px;margin-left: 50px">
            <div class="title" style="padding: 23px 0 50px 0">
              上层应用软件
            </div>
            <div class="content" style="font-size: 21px;letter-spacing: 1px;">
              <div style="text-indent:2em;">
                根据客户场景所需，结合AR智能眼镜进行软件应用的四大定制化开发。
              </div>
              <div>
                ● 用户界面定制化
              </div>
              <div>
                ● 软件功能定制化
              </div>
              <div>
                ● 业务流程定制化
              </div>
              <div>
                ● 交互方式定制化
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div ref="type3" class="web-product-platform">
      <div style="width: 1240px;text-align: left">
        <div>
          <div class="inline-block" style="width: 558px;">
            <div class="title" style="padding: 23px 0 45px 0">
              云端管理平台
            </div>
            <div class="content" style="font-size: 21px;letter-spacing: 1px;">
              <div style="text-indent:2em;">
                一款综合全面的企业移动化管理平台，为用户提供了基础的账号、设备、资料的信息化管理，同时又拥有实时信息存储、分析及统计等功能。具备通过场景学习、数据分析，形成知识沉淀，实现智慧共享。
              </div>
              <div>
                <div class="color-active inline-block" style="width: 20px">
                  ●
                </div>
                <div class="inline-block">
                  全面：可对接第三方后台系统，数据全链路贯通
                </div>
              </div>
              <div>
                <div class="color-active inline-block" style="width: 20px">
                  ●
                </div>
                <div class="inline-block">
                  高效：高效支撑企业百万级并发处理需求
                </div>
              </div>
              <div>
                <div class="color-active inline-block" style="width: 20px">
                  ●
                </div>
                <div class="inline-block">
                  效率：资料数据新增保存，效率提升20%
                </div>
              </div>
              <div>
                <div class="color-active inline-block" style="width: 20px">
                  ●
                </div>
                <div class="inline-block">
                  精细：多维度统计，可视化报表
                </div>
              </div>
              <div>
                <div class="color-active inline-block" style="width: 20px">
                  ●
                </div>
                <div class="inline-block">
                  自动：可与多种智能设备进行集成
                </div>
              </div>
              <div>
                <div class="color-active inline-block" style="width: 20px">
                  ●
                </div>
                <div class="inline-block">
                  灵活：公/私有云部署，支持多组织管理
                </div>
              </div>
            </div>
          </div>
          <div class="inline-block" style="width: 631px;margin-left: 50px">
            <img
              src="../../../assets/images/websites/product/云端管理平台.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div ref="type4" class="web-product-ar">
      <div style="width: 1240px">
        <div class="title">
          AR-AI算法识别系统
        </div>
        <div class="card">
          <div class="inline-block" style="width: 466px;">
            <img
              src="../../../assets/images/websites/product/图像识别.png"
              alt=""
            />
          </div>
          <div class="inline-block" style="width: 680px;padding: 0 47px">
            <div class="card-title">
              图像识别
            </div>
            <div class="card-content">
              ● 公有云全线免费试用1个月
            </div>
            <div class="card-content">
              ● 同步返回系统配置信息
            </div>
            <div class="card-content">
              ● 抗干扰能力强
            </div>
            <div class="card-content">
              ● 支持定制化识图，可满足非通用场景下的业务需求
            </div>
            <div class="card-content">
              ● 组合接口API调用，可自由选择需要的垂类识别服务，一次传图，
            </div>
            <div class="card-content" style="text-indent:20px;">
              多垂类同时识别
            </div>
          </div>
        </div>
        <div class="card">
          <div class="inline-block" style="width: 680px;padding: 0 47px">
            <div class="card-title">
              人脸识别
            </div>
            <div class="card-content">
              ● 公有云全线免费试用1个月
            </div>
            <div class="card-content">
              ● 支持活体检测
            </div>
            <div class="card-content">
              ● 端云能力齐全，适配多种应用场景
            </div>
            <div class="card-content">
              ● 稳定服务保障
            </div>
            <div class="card-content">
              ● 服务提供的应用程序接口功能简单易用；配套文档描述详细，方便
            </div>
            <div class="card-content" style="text-indent:20px;">
              客户使用与集成
            </div>
          </div>
          <div class="inline-block" style="width: 466px;">
            <img
              src="../../../assets/images/websites/product/人脸识别.png"
              alt=""
            />
          </div>
        </div>
        <div class="card">
          <div class="inline-block" style="width: 466px;">
            <img
              src="../../../assets/images/websites/product/车牌识别.png"
              alt=""
            />
          </div>
          <div class="inline-block" style="width: 680px;padding: 0 47px">
            <div class="card-title">
              车牌识别
            </div>
            <div class="card-content" style="margin-bottom: 26px">
              ● 公有云全线免费试用1个月
            </div>
            <div class="card-content" style="margin-bottom: 26px">
              ● 离线识别SDK
            </div>
            <div class="card-content" style="margin-bottom: 26px">
              ● 提供一体机和软件部署包两种私有化方案
            </div>
            <div class="card-content">
              ● 识别结果中可返回后台系统配置的字段内容，让业务功能更完善
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { remoteList } from "@//libs/dict/websites";

export default {
  name: "product",
  data() {
    return {
      topHeight: "",
      type1Height: "",
      type2Height: "",
      type3Height: "",
      type4Height: "",
      scrollData: [],
      remoteList
    };
  },
  mounted() {
    this.getHeight();
  },
  methods: {
    getHeight() {
      let _this = this;
      this.topHeight = this.$refs.top.scrollHeight;
      this.type1Height = this.$refs.type1.scrollHeight;
      this.type2Height = this.$refs.type2.scrollHeight;
      this.type3Height = this.$refs.type3.scrollHeight;
      this.type4Height = this.$refs.type4.scrollHeight;

      this.scrollData = [
        { val: 0 },
        { val: this.topHeight },
        { val: this.topHeight + this.type1Height },
        { val: this.topHeight + this.type1Height + this.type2Height },
        {
          val:
            this.topHeight +
            this.type1Height +
            this.type2Height +
            this.type3Height
        }
      ];
      localStorage.setItem("productScroll", JSON.stringify(this.scrollData));
      let index = this.$route.params.type;
      if (!index) {
        index = 0;
      }
      setTimeout(function() {
        _this.setScrollTop(index);
      }, 0);
    },
    setScrollTop(index) {
      document.documentElement.scrollTop = this.scrollData[index].val;
    }
  }
};
</script>

<style scoped></style>
