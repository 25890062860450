<template>
  <div class="web-page">
    <div ref="top" style="height: 43.07vw">
      <div>
        <img
          src="../../../assets/images/websites/about/关于我们-头.png"
          alt=""
          style="width: 100%"
        />
      </div>
    </div>
    <div ref="type1">
      <div>
        <div style="width: 1240px">
          <div class="title">
            公司介绍
          </div>
          <div class="content">
            上海极视云信息科技有限公司以XR智能眼镜为切入点，结合多种传感器方案，面向工业互联网及智能制造，打造以AI+AR为核心的智慧空间生态云平台。在协同设计，远程协助，数字可视化等场景有多个成功案例。
          </div>
        </div>
      </div>
<!--      <div class="background-theme">-->
<!--        <div style="width: 1240px">-->
<!--          <div class="title">-->
<!--            资质证书-->
<!--          </div>-->
<!--          <div>-->
<!--            <div class="inline-block" style="width: 606px">-->
<!--              <div>-->
<!--                <img-->
<!--                  src="../../../assets/images/websites/about/certificate/13-1.png"-->
<!--                  alt=""-->
<!--                  style="width: 606px;height: 387px;border-radius: 15px;"-->
<!--                />-->
<!--              </div>-->
<!--              <div style="position: relative;height: 313px;overflow-y: hidden">-->
<!--                <img-->
<!--                  src="../../../assets/images/websites/about/certificate/13-1.png"-->
<!--                  alt=""-->
<!--                  style="width: 606px;height: 387px;border-radius: 15px;transform:rotateX(180deg);"-->
<!--                />-->
<!--                <div-->
<!--                  style="width: 606px;height: 313px;background: linear-gradient(0deg, #344768,rgba(52, 71, 104, 0.67) );position: absolute;top: 0"-->
<!--                ></div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div-->
<!--              class="inline-block"-->
<!--              style="width: 582px;margin-left: 50px;padding-top: 128px"-->
<!--            >-->
<!--              <div>-->
<!--                <img-->
<!--                  src="../../../assets/images/websites/about/certificate/14-1.png"-->
<!--                  alt=""-->
<!--                  style="width: 582px;height: 372px;border-radius: 15px;  "-->
<!--                />-->
<!--              </div>-->
<!--              <div style="position: relative;height: 200px;overflow-y: hidden">-->
<!--                <img-->
<!--                  src="../../../assets/images/websites/about/certificate/14-1.png"-->
<!--                  alt=""-->
<!--                  style="width: 582px;height: 372px;border-radius: 15px;transform:rotateX(180deg);"-->
<!--                />-->
<!--                <div-->
<!--                  style="width: 582px;height: 200px;background: linear-gradient(0deg, #344768, rgba(52, 71, 104, 0.67));position: absolute;top: 0"-->
<!--                ></div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>

    <div ref="type2">
      <div style="width: 1240px">
        <div class="title">
          服务模式
        </div>
        <div
          class="content"
          style="font-family: Bold,sans-serif;padding-bottom: 35px"
        >
          协助客户快速了解，部署平台并提供相应的培训与技术支持。对待特殊需求可做进一步的定制化开发
        </div>
        <div style="margin-top: 100px">
          <div class="server-card" style="">
            <div class="server-card-icon">
              <img
                src="../../../assets/images/websites/about/type1.png"
                alt=""
              />
            </div>
            <div class="server-card-title">
              标准服务
            </div>
            <div class="server-card-star">
              ★
            </div>
            <div class="server-card-content">
              提供成熟与标准化的软硬件产品
            </div>
          </div>
          <div class="server-card" style="margin-left: 27px">
            <div class="server-card-icon">
              <img
                src="../../../assets/images/websites/about/type2.png"
                alt=""
              />
            </div>
            <div class="server-card-title">
              定制服务
            </div>
            <div class="server-card-star">
              ★
            </div>
            <div class="server-card-content">
              可根据客户业务场景所需进行软件定制化
            </div>
          </div>
          <div class="server-card" style="margin-left: 27px">
            <div class="server-card-icon">
              <img
                src="../../../assets/images/websites/about/type3.png"
                alt=""
              />
            </div>
            <div class="server-card-title">
              PaaS/SaaS能力
            </div>
            <div class="server-card-star">
              ★
            </div>
            <div class="server-card-content">
              可提供软件算法及SDK的能力，客户可自行做软件集成
            </div>
          </div>
        </div>
      </div>
    </div>
    <div ref="type3">
      <div class="title">
        <p>合作伙伴</p>
      </div>
      <div class="content">
        <el-carousel height="427px">
          <el-carousel-item>
            <div style="width: 1240px">
              <img
                src="../../../assets/images/websites/about/hezuo1.png"
                alt=""
                style="width: 100%"
              />
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div style="width: 1240px">
              <img
                src="../../../assets/images/websites/about/hezuo2.png"
                alt=""
                style="width: 100%"
              />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!--    <div>-->
    <!--      <div>-->
    <!--        <img-->
    <!--          src="../../../assets/images/websites/about/map.png"-->
    <!--          alt=""-->
    <!--          style="width: 100%"-->
    <!--        />-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>`

<script>
export default {
  name: "about",
  data() {
    return {
      topHeight: "",
      type1Height: "",
      type2Height: "",
      type3Height: "",
      scrollData: []
    };
  },
  mounted() {
    this.getHeight();
  },
  methods: {
    getHeight() {
      let _this = this;
      this.topHeight = this.$refs.top.scrollHeight;
      this.type1Height = this.$refs.type1.scrollHeight;
      this.type2Height = this.$refs.type2.scrollHeight;
      this.scrollData = [
        { val: 0 },
        { val: this.topHeight },
        { val: this.topHeight + this.type1Height },
        { val: this.topHeight + this.type1Height + this.type2Height }
      ];
      localStorage.setItem("aboutScroll", JSON.stringify(this.scrollData));
      let index = this.$route.params.type;
      if (!index) {
        index = 0;
      }
      setTimeout(function() {
        _this.setScrollTop(index);
      }, 0);
    },
    setScrollTop(index) {
      document.documentElement.scrollTop = this.scrollData[index].val;
    }
  }
};
</script>

<style scoped></style>
