<template>
  <div class="web-home" align="center">
    <div class="web-home-banner">
      <div>
        <video
          ref="video"
          src="../../../assets/images/websites/home/acmeBanner.mp4"
          style="width: 100%"
          autoplay
          loop
          muted
        />
        <div class="mask"></div>
      </div>
    </div>
    <div class="web-home-new">
      <el-carousel height="700px">
        <el-carousel-item>
          <div style="width: 1240px">
            <el-row>
              <el-col :span="12" align="center">
                <div class="web-home-new-image">
                  <img
                    src="../../../assets/images/websites/home/new-image2.png"
                    alt=""
                  />
                </div>
              </el-col>
              <el-col :span="12" align="center">
                <div class="web-home-new-title">
                  <p>5G+AR助力行业数字化浪潮的加速到来</p>
                </div>
                <div class="web-home-new-content">
                  <p>
                    2019年6月，国家工信部向三大运营商正式颁发5G牌照，标志着我国正式进入5G时代。5G作为跨时代技术，相较于4G网络，不仅可以提供良好的用户体验和较大的网络容量，还将开启万物互联的时代，同时渗透至千行百业。其中AR技术由于5G的加持，众多应用场景成功落地。运营商利用品牌和渠道优势，以5G为契机，整合合作伙伴的优质技术资源，快速切入市场，逐步参与构建基于AR技术的应用生态体系。
                  </p>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div>
            <el-row>
              <el-col :span="12">
                <div class="web-home-new-image">
                  <img
                    src="../../../assets/images/websites/home/new-image3.png"
                    alt=""
                  />
                </div>
              </el-col>
              <el-col :span="12">
                <div class="web-home-new-title">
                  <p>5G中央空调应用示范基地揭牌仪式</p>
                </div>
                <div class="web-home-new-content">
                  <p>
                    海尔中央空调智能互联工厂作为“灯塔工厂”及5G中央空调应用示范基地,由海尔联合中国信通院、中国家电院、中国移动通信集团有限公司、华为技术有限公司与极视云科技共同打造。
                    <br />
                    互联工厂落地了AR远程协作、AR作业指导、AR巡检可视化、人脸识别等功能。
                  </p>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div>
            <el-row>
              <el-col :span="12">
                <div class="web-home-new-image">
                  <img
                    src="../../../assets/images/websites/home/new-image1.png"
                    alt=""
                  />
                </div>
              </el-col>
              <el-col :span="12">
                <div class="web-home-new-title">
                  <p>5G+AR赋能智慧医疗</p>
                </div>
                <div class="web-home-new-content">
                  <p>
                    在以往，不同地区的医生之间主要是依靠微信等方法传递视频、图片或者语音，很难做到同步，在与生命的赛跑中很可能会出现“慢半拍“的情况。5G+AR技术则完美解决了这一问题。在5G网络加持之下，手术现场医生可以通过AR智能眼镜的第一视角功能将视频快速传递到后端专家的屏幕上，并结合心电监护视频等其他影像，给予后端专家全面的信息支持。
                    <br />
                    不仅如此，通过5G+AR技术，可以帮助将大医院的技术能力下沉辐射到更广泛区域。在新技术的帮助下，可以有效助推分级医疗制度，缓解广大群众就医难的问题。
                  </p>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="web-home-air">
      <div>
        <div style="width: 1240px">
          <div class="web-home-air-title">
            <button
              class="web-home-air-title-btn"
              type="button"
            >
              AIR CLOUD-平台服务
            </button>
<!--            <p style="line-height: 1px">(可点击进入)</p>-->
          </div>
          <div class="web-home-air-content">
            <p>
              上海极视云是从事与传感器结合和计算机视觉技术研究的科技公司。主要产业是为以AR智能眼镜为代表的智能硬件提供相应的软件应用及解决方案。目前应用方向是针对垂直市场产生的“解放双手，第一视角”的可穿戴应用需求，结合智能眼镜硬件方案形成完整的操作系统，从云到端的应用软件的解决方案。
            </p>
          </div>
          <div class="web-home-air-product">
            <div
              class="product-item"
              v-for="(item, index) in productList"
              :key="index"
            >
              <div class="product-item-card">
                <div class="product-item-card-center">
                  <img :src="item.imgUrl" alt="" />
                </div>
              </div>
              <div class="product-item-name">
                <p>{{ item.name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="web-home-solution">
      <div style="width: 1240px;">
        <div class="web-home-solution-title">
          <p>解决方案</p>
        </div>
        <div class="web-home-solution-content">
          <div class="solution">
            <div
              :span="8"
              v-for="(item, index) in solutionList"
              :key="index"
              class="solution-item"

            >
              <div class="background">
                <img :src="item.backUrl" alt="" />
              </div>
              <div class="layer">

                <div class="layer0"></div>
                <div class="layer1">
                  <div>
                    <img :src="item.iconUrl" alt="" />
                  </div>
                  <div class="layer2">
                    <p>
                      {{ item.text }}
                    </p>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { productList, solutionList } from "@/libs/dict/websites.js";
export default {
  name: "home",
  data() {
    return {
      productList,
      solutionList
    };
  },
  methods: {
    goto(item) {
      this.$router.push({
        name: item.routerName,
        params: {
          type: item.type
        }
      });
    },
    goAirCloud() {
      let url = this.$router.resolve({
        name: "airService"
      });
      window.open(url.href, "_blank");
    }
  }
};
</script>

<style scoped></style>
