<template>
  <div class="live-device" v-loading="loading">
    <div class="live-device-title">
      <el-row>
        <el-col :span="12" align="left">
          <el-select
            style="width: 300px"
            v-model="keyword"
            @change="queryDataByIndex"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
            <el-option :key="0" label="全部" value=""></el-option>
            <el-option
              v-for="item in deviceList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            ></el-option
          ></el-select>
        </el-col>
      </el-row>
    </div>
    <div class="live-device-content">
      <el-table
        :data="tableData"
        style="width: 100%"
        header-cell-class-name="headerClass"
      >
        <el-table-column prop="name" label="设备编号" align="center">
          <template slot-scope="scope">
            <div>
              <p>
                {{ scope.row.serialNumber }}
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="设备名称" align="center">
          <template slot-scope="scope">
            <div>
              <p>
                {{ getDeviceBySerialNumber(scope.row.serialNumber).name }}
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="duration" label="时长" align="center">
          <template slot-scope="scope">
            <div>
              <p>
                {{ formatDuration(scope.row.duration) }}
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="startTime" label="开始时间" align="center">
        </el-table-column>
        <el-table-column prop="updateTime" label="结束时间" align="center">
          <template slot-scope="scope">
            <div>
              <p>
                {{
                  getEndTimeAndFormat(scope.row.startTime, scope.row.duration)
                }}
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="类型" align="center">
          <template slot-scope="scope">
            <div>
              <p>
                {{
                  deviceTypeList[
                    getDeviceBySerialNumber(scope.row.serialNumber).type
                  ]
                }}
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <button
              type="button"
              class="m-btn-live-play"
              @click="play(scope.row)"
              style="margin-right: 16px"
            >
              <i class="font_family m-icon-play"></i>
            </button>
            <button
              type="button"
              class="m-btn-live-delete"
              @click="handleDelete(scope.row)"
            >
              <i class="font_family m-icon-delete"></i>
            </button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="live-device-footer">
      <el-pagination
        background
        layout="total,prev, pager, next"
        :current-page="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.totalNum"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <el-dialog title="直播回看" :visible.sync="showPlayDialog" width="650px">
      <VideoPlayer :play-info="playInfo" v-if="showPlayDialog"></VideoPlayer>
    </el-dialog>
  </div>
</template>

<script>
import { delRecord, queryRecords } from "@/api/live/history";
import { getDetermine } from "@/libs/tools";
import { queryDevices } from "@/api/live/device";
import PlayVideo from "@/views/vr-live/history/components/PlayVideo";
import { deviceTypeList } from "@//libs/dict/live";
import VideoPlayer from "@//views/vr-live/home/components/VideoPlayer";

export default {
  name: "history",
  components: { VideoPlayer, PlayVideo },
  data() {
    return {
      loading: true,
      keyword: "",
      tableData: [],
      deviceList: [],
      pageInfo: {
        currentPage: 1,
        pageSize: 5,
        totalNum: 0
      },
      showPlayDialog: false,
      playUrl: "",
      dialogFull: false,
      deviceTypeList,
      playInfo: {
        url: "",
        deviceType: "",
        type: "history"
      }
    };
  },
  mounted() {
    this.queryDeviceDate();
  },
  methods: {
    queryDeviceDate() {
      let data = {
        pageIndex: 1,
        pageSize: 1000,
        keyword: ""
      };
      queryDevices(data).then(res => {
        if (res.code === 200) {
          this.deviceList = res.data.list;
        } else {
          this.deviceList = [];
        }
        this.queryDataByIndex();
      });
    },
    handleCurrentChange(val) {
      if (this.pageInfo.currentPage !== val) {
        this.pageInfo.currentPage = val;
        this.queryDataByIndex();
      }
    },
    getVideoDetermine(start, end) {
      return getDetermine(start, end);
    },

    formatDuration(duration) {
      // 判断大于1小时显示，否则显示分钟，判断是否大于1分钟，否则显示秒
      if (duration >= 3600) {
        return `${Math.floor(duration / 3600)}小时${Math.floor(
          (duration % 3600) / 60
        )}分钟`;
      } else if (duration >= 60) {
        return `${Math.floor(duration / 60)}分钟${duration % 60}秒`;
      } else {
        return `${duration}秒`;
      }
    },
    getEndTimeAndFormat(start, duration) {
      // 获取结束时间
      let end = new Date(start);
      end.setSeconds(end.getSeconds() + duration);
      // 格式化时间，月和日补齐两位
      let year = end.getFullYear();
      let month = end.getMonth() + 1;
      let day = end.getDate();
      let hour = end.getHours();
      let minute = end.getMinutes();
      let second = end.getSeconds();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      hour = hour < 10 ? "0" + hour : hour;
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second
      );
    },
    getDeviceBySerialNumber(serialNumber) {
      let device = this.deviceList.find(item => {
        return item.serialNumber === serialNumber;
      });
      return device;
    },

    queryDataByIndex() {
      let data = {
        pageNum: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize,
        keyword: this.keyword
      };
      queryRecords(data).then(res => {
        if (res.code === 200) {
          if (res.data.list.length > 0) {
            this.tableData = res.data.list;
            this.pageInfo.totalNum = res.data.total;
          } else {
            if (this.pageInfo.currentPage > 1) {
              this.pageInfo.currentPage--;
              this.queryDataByIndex();
            } else {
              this.tableData = [];
              this.pageInfo.totalNum = 0;
            }
          }
        } else {
          this.$message.warning(res.msg);
          this.tableData = [];
          this.pageInfo.total = 0;
        }
        this.loading = false;
      });
    },
    handleDelete(row) {
      let _this = this;
      this.$confirm("此操作将删除该视频回看, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {

        delRecord(row.id).then(res => {
          if (res.code === 200) {
            _this.queryDataByIndex();
          } else {
            _this.$message.warning(res.msg);
          }
        });
      });
    },
    play(row) {
      this.playInfo.url = row.path;
      this.playInfo.deviceType = row.type;
      this.showPlayDialog = true;
    },
    fullPage(data) {
      this.dialogFull = data;
    }
  }
};
</script>

<style scoped></style>
