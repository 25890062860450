<template>
  <div class="web-solution">
    <div class="web-solution-banner">
      <div>
        <img
          src="../../../assets/images/websites/solution/banner1.png"
          alt=""
          style="width: 100%"
        />
      </div>
    </div>
    <div class="web-home-solution">
      <div style="width: 1240px;padding-top: 20px">
        <div class="web-home-solution-title">
          <p>解决方案</p>
        </div>
        <div class="web-home-solution-content">
          <div class="solution">
            <div
              :span="8"
              v-for="(item, index) in solutionList"
              :key="index"
              class="solution-item"
              @click="goto(item)"
            >
              <div class="background">
                <img :src="item.backUrl" alt="" />
              </div>
              <div class="layer">
                <div class="layer0"></div>
                <div class="layer1">
                  <div>
                    <img :src="item.iconUrl" alt="" />
                  </div>
                  <div class="layer2">
                    <p>
                      {{ item.text }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { solutionList } from "@/libs/dict/websites.js";

export default {
  name: "solution",
  data() {
    return {
      solutionList
    };
  },
  methods: {
    goto(item) {
      this.$router.push({
        name: item.routerName,
        params: {
          type: item.type
        }
      });
    }
  }
};
</script>

<style scoped></style>
