<template>
  <div class="live-main">
    <el-container>
      <el-aside>
        <live-aside></live-aside>
      </el-aside>
      <el-main>
        <div
          style="background-color: white;height: calc(100vh - 40px);border-radius: 30px"
        >
          <router-view />
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import LiveAside from "@/components/live/LiveAside";
export default {
  name: "LiveMain",
  components: { LiveAside }
};
</script>

<style scoped></style>
