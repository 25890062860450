<template>
  <div class="form">
    <div class="title">
      <div>
        <p>
          登录
        </p>
      </div>
    </div>
    <el-form ref="form" :model="form">
      <el-form-item>
        <el-input
          v-model="form.userName"
          maxlength="11"
          style="padding:0 10px"
          prefix-icon="font_family m-icon-user"
        >
          <!--          <i slot="prefix" class="el-input__icon "></i>-->
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          type="password"
          v-model="form.password"
          maxlength="16"
          style="padding:0 10px"
          prefix-icon="font_family m-icon-password"
          @keyup.enter.native="loginService()"
        ></el-input>
      </el-form-item>
      <div align="left">
        <el-checkbox v-model="form.keep"
          ><p style="color: #ffffff">{{ $t("public.keep") }}</p></el-checkbox
        >
      </div>
      <div>
        <button @click="loginService" class="m-btn-live-login" type="button">
          {{ $t("public.login") }}
        </button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  getCookies,
  getMD5,
  removeCookie,
  repeat,
  setCookies
} from "@/libs/util";
export default {
  name: "LoginForm",
  data() {
    return {
      form: {
        userName: "",
        password: "",
        keep: false
      },
      passwordLength: null
    };
  },
  mounted() {
    this.getLoginInfo();
  },
  methods: {
    ...mapActions(["handleLogin"]),
    getLoginInfo() {
      if (getCookies("keep")) {
        this.form.userName = getCookies("userName");
        this.form.keep = getCookies("keep");
        this.passwordLength = parseInt(getCookies("passwordLength"));
        this.form.password = repeat("*", this.passwordLength);
      }
    },
    setLoginInfo() {
      setCookies("userName", this.form.userName);
      setCookies(
        "password",
        this.form.password !== repeat("*", this.passwordLength)
          ? getMD5(this.form.password)
          : getCookies("password")
      );
      setCookies("passwordLength", this.form.password.length);
      setCookies("keep", this.form.keep);
    },
    removeLoginInfo() {
      removeCookie("userName");
      removeCookie("password");
      removeCookie("passwordLength");
      removeCookie("keep");
    },
    loginService() {
      let data = {
        userName: this.form.userName,
        password:
          this.form.password !== repeat("*", this.passwordLength)
            ? getMD5(this.form.password)
            : getCookies("password")
      };
      this.handleLogin(data).then(res => {
        if (res.code === 200) {
          if (this.form.keep) {
            this.setLoginInfo();
          } else {
            this.removeLoginInfo();
          }
          this.$router.push({
            name: "liveHome"
          });
        } else {
          this.$message.warning(res.msg);
        }
        console.log(res);
      });
    },
    goto(routerName) {
      this.$router.push({
        name: routerName
      });
    }
  }
};
</script>

<style scoped></style>
