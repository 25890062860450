import axios from "@/libs/request/api.request";
export const getAllCompanyAuth = data => {
  return axios.request({
    url:
      "/system/company/audit/query?pageIndex=" +
      data.pageIndex +
      "&pageSize=" +
      data.pageSize,
    data,
    method: "post"
  });
};
export const queryAuthByCompanyId = data => {
  return axios.request({
    url: "/system/company/audit/queryByCompanyId?companyId=" + data.companyId,
    data,
    method: "get"
  });
};

export const saveCompanyAuth = data => {
  return axios.request({
    url: "/system/company/audit/save",
    data,
    method: "post"
  });
};
export const updateCompanyAuth = data => {
  return axios.request({
    url: "/system/company/audit/update",
    data,
    method: "post"
  });
};

export const updateAuditStatus = data => {
  return axios.request({
    url: "/system/company/audit/update/status",
    data,
    method: "post"
  });
};

export const saveAuthRecord = data => {
  return axios.request({
    url: "/system/company/audit/save/record",
    data,
    method: "post"
  });
};

export const getAuthRecord = data => {
  return axios.request({
    url: "/system/company/audit/query/record",
    data,
    method: "post"
  });
};

// 组织管理

export const getCompanyList = data => {
  return axios.request({
    url: "/system/company/query/list",
    data,
    method: "get"
  });
};
