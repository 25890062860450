<template>
  <div class="web-footer" align="center">
    <div style="width: 90%">
      <div class="top" align="left">
        <div style="padding-top: 70px">
          <el-row>
            <el-col :span="6">
              <p @click="goRouter('product')" class="web-footer-menu">
                产品介绍
              </p>
            </el-col>

            <el-col :span="6">
              <p @click="goRouter('solution')" class="web-footer-menu">
                解决方案
              </p>
            </el-col>

            <el-col :span="6">
              <p @click="goRouter('about')" class="web-footer-menu">
                关于我们
              </p>
            </el-col>
            <el-col :span="6">
              <p>
                关注极视云
              </p>
            </el-col>
            <!--            <el-col :span="4">-->
            <!--              <p>-->
            <!--                关注TOPVISION-->
            <!--              </p>-->
            <!--            </el-col>-->
          </el-row>
        </div>
      </div>
      <div class="content" align="left">
        <div style="padding-top: 10px">
          <el-row>
            <el-col :span="6">
              <div
                v-for="(item, index) in productList"
                :key="index"
                class="web-footer-menu"
              >
                <p @click="goto(item)">
                  {{ item.name }}
                </p>
              </div>
              <div class="web-footer-menu">
                <p @click="goLive">
                  直播系统
                </p>
              </div>
            </el-col>

            <el-col :span="6">
              <div
                v-for="(item, index) in solutionList"
                :key="index"
                class="web-footer-menu"
              >
                <p @click="goto(item)">
                  {{ item.name }}
                </p>
              </div>
            </el-col>

            <el-col :span="6">
              <div
                v-for="(item, index) in aboutList"
                :key="index"
                class="web-footer-menu"
              >
                <p @click="goto(item)">
                  {{ item.name }}
                </p>
              </div>
            </el-col>
            <el-col :span="6">
              <!--              <div>-->
              <!--                <p>-->
              <!--                  致电：021-69029712-->
              <!--                </p>-->
              <!--              </div>-->
              <!--              <div>-->
              <!--                <p>-->
              <!--                  邮件：sales@topvision-cv.com-->
              <!--                </p>-->
              <!--              </div>-->
              <!--              <div>-->
              <!--                <p>-->
              <!--                  时间：9:00-18:00-->
              <!--                </p>-->
              <!--              </div>-->

              <div style="margin-top: 30px">
                <img
                  src="../../assets/images/websites/二维码.jpg"
                  alt=""
                  style="width: 147px"
                />
              </div>

              <!--     <div>
                <p>
                  建议与反馈
                </p>
              </div>-->
            </el-col>
            <!--            <el-col :span="4">-->
            <!--              <img-->
            <!--                alt=""-->
            <!--                src="../assets/image/main/erweima.png"-->
            <!--                style="height: 146px;width: 146px"-->
            <!--              />-->
            <!--            </el-col>-->
          </el-row>
        </div>
      </div>
      <div class="bottom">
        <div style="display: inline-block">
          上海极视云信息科技有限公司 版权所有 |
        </div>
        <div style="display: inline-block;padding-left: 5px;  cursor: pointer;" @click="openICP">
          沪ICP备2021020579号-1 |
        </div>
        <div style="display: inline-block;  cursor: pointer;" @click="openGov">
          <img
            src="../../assets/备案图标.png"
            style="float:left;margin: 18px 5px 0"
            alt=""
          />

          沪公网安备 31011802004731号
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { productList, solutionList } from "../../libs/dict/websites.js";

export default {
  name: "webFooter",
  data() {
    return {
      productList,
      solutionList,
      aboutList: [
        // { routerName: "about", type: 1, name: "公司资质" },
        { routerName: "about", type: 2, name: "服务模式" },
        { routerName: "about", type: 3, name: "合作伙伴" }
      ]
    };
  },
  methods: {
    goRouter(name) {
      this.$router.push({
        name: name
      });
    },
    goto(item) {
      let currentRouterName = this.$route.name;
      if (currentRouterName !== item.routerName) {
        this.$router.push({
          name: item.routerName,
          params: {
            type: item.type
          }
        });
      } else {
        let productScroll = JSON.parse(localStorage.getItem("productScroll"));
        let aboutScroll = JSON.parse(localStorage.getItem("aboutScroll"));
        let solutionScroll = JSON.parse(localStorage.getItem("solutionScroll"));
        if (currentRouterName === "product") {
          document.documentElement.scrollTop = productScroll[item.type].val;
        } else if (currentRouterName === "about") {
          document.documentElement.scrollTop = aboutScroll[item.type].val;
        } else {
          document.documentElement.scrollTop = solutionScroll[item.type].val;
        }
      }
    },
    goLive() {
      this.$router.push({
        name: "liveHome"
      });
    },
    openICP() {
      window.open("https://beian.miit.gov.cn", "_black");
    },
    openGov() {
      window.open(
        "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011802004731",
        "_black"
      );
    }
  }
};
</script>

<style scoped lang="less">
.top {
  height: 156px;
  border-bottom: 1px solid rgba(233, 233, 237, 0.55);
  font-size: 25px;
  font-family: Adobe Heiti Std, serif;
  font-weight: normal;
  color: #e9e9ed;
  /*line-height: 32px;*/
}
.content {
  height: 262px;
  font-size: 16px;
  font-family: Source Han Sans CN, serif;
  font-weight: 400;
  color: #e9e9ed;
  line-height: 22px;
}
.bottom {
  /*padding-top: 10px;*/
  height: 60px;
  border-top: 1px solid rgba(233, 233, 237, 0.55);
  font-size: 17px;
  font-family: Source Han Sans CN, serif;
  font-weight: 400;
  color: #d8d7d7;
  line-height: 60px;
}
</style>
