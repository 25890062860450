<template>
  <div class="live-home" v-loading="loading">
    <div class="live-home-content">
      <el-table
        :data="tableData"
        style="width: 100%"
        header-cell-class-name="headerClass"
      >
        <el-table-column prop="serialNumber" label="设备编号" align="center">
        </el-table-column>
        <el-table-column label="设备信息" prop="device">
          <template slot-scope="scope">
            <div>名称:{{ scope.row.device.name }}</div>
            <div>描述:{{ scope.row.device.description }}</div>
          </template>
        </el-table-column>
        <el-table-column label="视频信息" prop="video">
          <template slot-scope="scope">
            <div>
              分辨率:{{ scope.row.video.width + "X" + scope.row.video.height }}
            </div>
            <div>帧数:{{ scope.row.video.fps }}</div>
            <div>编码:{{ scope.row.video.codeName }}</div>
          </template>
        </el-table-column>
        <el-table-column label="音频信息" prop="audio">
          <template slot-scope="scope">
            <div>采样率:{{ scope.row.audio.sampleRate }}</div>
            <div>编码:{{ scope.row.audio.codeName }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="aliveSecond" label="时长" align="center">
          <template slot-scope="scope">
            <div>
              <p>{{ formatDuration(scope.row.aliveSecond) }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <button
              type="button"
              class="m-btn-live-play"
              @click="play(scope.row)"
            >
              <i class="font_family m-icon-play"></i>
            </button>
            <!--            <button-->
            <!--              type="button"-->
            <!--              class="m-btn-live-delete"-->
            <!--              @click="handleDelete(scope.row)"-->
            <!--            >-->
            <!--              <i class="font_family m-icon-kick"></i>-->
            <!--            </button>-->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="live-home-footer">
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.totalNum"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="直播观看"
      :visible.sync="showPlayDialog"
      width="650px"
      @close="closePlayDialog"
    >
      <!--      <play-rtc-->
      <!--        v-if="showPlayDialog"-->
      <!--        :data="playUrl"-->
      <!--        @full="fullPage"-->
      <!--      ></play-rtc>-->
      <VideoPlayer
        v-if="showPlayDialog"
        :playInfo="playInfo"
        ref="videoPlayer"
      ></VideoPlayer>
    </el-dialog>
  </div>
</template>

<script>
import { kickOutDevice, queryLives } from "@/api/live/live";

import VideoPlayer from "@//views/vr-live/home/components/VideoPlayer";

export default {
  name: "home",
  components: { VideoPlayer },
  data() {
    return {
      loading: true,
      tableData: [],
      playUrl: "111",
      showPlayDialog: false,
      pageInfo: {
        totalNum: 0,
        currentPage: 1,
        pageSize: 10
      },
      time1: "",
      dialogFull: false,
      playInfo: {
        url: "",
        deviceType: "",
        type: "live"
      }
    };
  },
  mounted() {
    this.queryDataByIndex();
    this.interval();
  },
  beforeDestroy() {
    clearInterval(this.time1);
  },
  methods: {
    interval() {
      this.time1 = setInterval(this.queryDataByIndex, 5000);
    },
    queryDataByIndex() {
      let data = {
        pageNum: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize
      };
      queryLives(data).then(res => {
        if (res.code === 200) {
          this.tableData = res.data;
          this.pageInfo.totalNum = res.data.length;
        } else {
          this.$message.warning(res.msg);
        }
        this.loading = false;
      });
    },

    handleDelete(row) {
      let _this = this;
      this.$confirm("此操作将踢出该直播, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        let data = {
          clientId: row.clientId,
          deviceId: row.device.id
        };
        kickOutDevice(data).then(res => {
          if (res.code === 200) {
            _this.queryDataByIndex();
          } else {
            _this.$message.warning(res.msg);
          }
        });
      });
    },
    play(row) {
      this.playInfo.url = row.originUrl;
      this.playInfo.deviceType = row.device.type;
      this.showPlayDialog = true;
    },
    closePlayDialog() {
      this.$refs.videoPlayer.close();
    },
    formatDuration(duration) {
      // 判断大于1小时显示，否则显示分钟，判断是否大于1分钟，否则显示秒
      if (duration >= 3600) {
        return `${Math.floor(duration / 3600)}小时${Math.floor(
          (duration % 3600) / 60
        )}分钟`;
      } else if (duration >= 60) {
        return `${Math.floor(duration / 60)}分钟${duration % 60}秒`;
      } else {
        return `${duration}秒`;
      }
    },
    handleCurrentChange(val) {
      if (this.pageInfo.currentPage !== val) {
        this.pageInfo.currentPage = val;
        this.queryDataByIndex();
      }
    },
    fullPage(data) {
      this.dialogFull = data;
    }
  }
};
</script>

<style scoped></style>
