<template>
  <div class="live-play" :style="style">
    <video id="localVideo" autoplay :src="data" loop></video>
    <div class="live-play-menu">
      <el-row>
        <el-col :span="12">
          <button
            @click="switchVR()"
            type="button"
            class="m-btn-live-switch"
          ></button>
        </el-col>
        <el-col :span="12">
          <button
            @click="fullScreen()"
            type="button"
            :class="fullPage ? 'm-btn-live-full-active' : 'm-btn-live-full'"
          ></button>
        </el-col>
      </el-row>
    </div>
    <div class="live-play-expanded" v-show="showVR">
      <div id="container-vr"></div>
    </div>
  </div>
</template>

<script>
import { VR } from "@//libs/sdk/live/mxreality";
import * as THREE from "three";
export default {
  name: "PlayVideo",
  props: {
    data: String
  },
  data() {
    return {
      showVR: false,
      vr: "",
      container: "",
      renderer: "",
      style: {},
      fullPage: false
    };
  },
  methods: {
    switchVR() {
      if (!this.showVR) {
        this.showVR = true;
        // console.log(THREE);
        window.THREE = THREE; // 重要，不设置则会报THREE未定义错误！！！！！！
        let videoTag = document.getElementById("localVideo");
        if (videoTag.paused) {
          videoTag.play();
        }
        this.container = document.getElementById("container-vr");
        this.renderer = new THREE.WebGLRenderer();
        this.container.appendChild(this.renderer.domElement);

        let scene = new THREE.Scene();
        let geometry = new THREE.SphereBufferGeometry(200, 60, 40);
        geometry.scale(-1, 1, 1);
        let texture = new THREE.VideoTexture(videoTag);

        let material = new THREE.MeshBasicMaterial({
          map: texture
        });
        let mesh = new THREE.Mesh(geometry, material);
        scene.add(mesh);
        this.vr = new VR(scene, this.renderer, this.container);

        this.vr.init(function() {});
      } else {
        while (this.container.children.length) {
          this.container.removeChild(this.container.children[0]);
        }
        // this.container.removeChild(this.renderer.domElement);
        this.renderer.dispose();
        this.renderer.forceContextLoss();
        // this.renderer.context = null;
        this.renderer.domElement = null;
        this.renderer = null;
        this.showVR = false;
      }
    },
    fullScreen() {
      this.fullPage = !this.fullPage;
      if (this.fullPage) {
        this.$emit("full", this.fullPage);
        this.style = {
          position: "fixed",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: "100%",
          height: "100%"
        };
      } else {
        this.$emit("full", this.fullPage);
        this.style = {};
      }
    }
  }
};
</script>

<style scoped></style>
