<template>
  <div class="live-aside">
    <div class="live-aside-title">
      <div class="content">
<!--        <img-->
<!--          src="../../assets/images/vr-live/acme.png"-->
<!--          alt=""-->
<!--          style="width: 80%"-->
<!--        />-->
      </div>
    </div>
    <div class="live-aside-content" align="center">
      <div
        @click="openPage(item.routerName)"
        :class="
          currentRouterName === item.routerName
            ? 'menu-item active'
            : 'menu-item '
        "
        v-for="(item, index) in menuList"
        :key="index"
      >
        <div class="content">
          <div class="icon"><i :class="item.icon"></i></div>

          <div class="name">{{ item.title }}</div>

          <!--          <p></p>-->
        </div>
      </div>
    </div>
    <div class="live-aside-footer">
      <div class="content">
        <div style="height: 40px;">
          <el-row>
            <el-col :span="8" align="right">
              <div
                style="width: 40px;height: 40px;border-radius: 20px;background-color: #23B491;"
                align="center"
              >
                <div style="padding-top: 5px">
                  <i class="font_family m-icon-user"></i>
                </div>
              </div>
            </el-col>
            <el-col :span="8" align="left">
              <div style="height: 40px;margin-left: 12px">
                <p style="line-height: 0">{{ userInfo.realName }}</p>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <button @click="logOutPge" class="m-btn-live-logOut">
                  退出登录
                </button>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "LiveAside",
  data() {
    return {
      userInfo: this.$store.state.user.userInfo,
      currentRouterName: "",
      menuList: [
        {
          title: this.$t("menu.live"),
          routerName: "liveHome",
          icon: "font_family m-icon-live"
        },
        {
          title: this.$t("menu.device"),
          routerName: "liveDevice",
          icon: "font_family m-icon-device"
        },
        {
          title: this.$t("menu.history"),
          routerName: "liveHistory",
          icon: "font_family m-icon-history"
        }
      ]
    };
  },
  mounted() {
    this.currentRouterName = this.$route.name;
  },
  watch: {
    "$route.name": function(newVal) {
      this.currentRouterName = newVal;
    }
  },
  methods: {
    ...mapActions(["handleLogOut"]),
    openPage(routerName) {
      this.$router.push({
        name: routerName
      });
    },
    logOutPge() {
      this.handleLogOut().then(res => {
        if (res.code === 200) {
          this.$router.push({
            name: "liveLogin"
          });
        }
      });
    }
  }
};
</script>

<style scoped></style>
